import React from 'react';
import './Servicing.css';
import Layout from '../template/Layout';
import SubHeader from "../components/SubHeader";
import {Trans, useTranslation} from 'react-i18next';
import {getLng} from "../helpers";
import { Helmet } from 'react-helmet';

export default function Servicing(props) {
    const {  i18n } = useTranslation();

    const activeLng = getLng(i18n)

  const Distressed = () => {
    if(activeLng === 'en') {
      return <li>Distressed assets search in Brazil and abroad</li>
    } else {
      return null
    }
  }

  const MonitoringServicing = () => {
    if(activeLng !== 'en') {
      return <li style={{fontSize: 17}}>Monitoramento em tempo real de centenas de fontes de informações para localizar o devedor e acompanhar sua movimentação patrimonial;</li>
    } else {
      return null
    }
  }

  return (
    <Layout>
        <Helmet>
          <title>Servicing</title>
        </Helmet>

        <section className="body-content" id="page-servicing">
            <section className="page-title background-title dark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-uppercase">Servicing </h3>
                        </div>
                    </div>
                </div>
            </section>
            <SubHeader nameImage='frase_servicing.jpg' />
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p style={{fontSize: 17}}>
                              <Trans>
                                A plataforma de <i>servicing</i> da Jive conta com mais de 10 anos de atuação na gestão e recuperação de créditos atrasados de alta complexidade e ativos <i>distressed</i> em geral. A Jive conta com uma equipe multidisciplinar totalmente dedicada à gestão, negociação e cobrança de créditos corporativos, além de áreas especializadas em ativos imobiliários e na investigação e localização de ativos, no Brasil e no exterior. Com uma rede de escritórios de advocacia parceiros espalhados pelo Brasil e <i>offshore</i>, a Jive utiliza tecnologias inovadoras incluindo <i>big data</i> e <i>analytics</i> para definição das melhores estratégias.
                              </Trans>
                            </p>
                            { activeLng !== 'en' && (<p style={{fontSize: 17}}>
                              <Trans>
                                Focamos na prestação de serviço para empresas de grande porte, com carteiras de créditos inadimplidos com valor total acima de R$ 100 milhões e R$ 1 milhão por crédito.
                              </Trans>
                            </p>)}
                        </div>
                    </div>
                    <div className="page-content">
                    <div className="container content">
                        <div className='content-bullet'>
                            <div className='bullet'>
                                <div>
                                    <div>
                                        <h6><small><Trans>+de</Trans></small> 11800</h6>
                                        <p>
                                          <Trans>
                                            processos judiciais
                                          </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bullet bullet-mobile' style={{top: 210}}>
                                <div>
                                    <div>
                                        <h6><small><Trans>+de</Trans></small> 5700</h6>
                                        <p>
                                          <Trans>
                                            créditos
                                          </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bullet-fake'></div>
                            <div className='bullet'>
                                <div>
                                    <div>
                                        <h6><small><Trans>+de</Trans></small> 2600</h6>
                                        <p>
                                          <Trans>
                                          imóveis                                       
                                          </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bullet bullet-mobile' style={{top: 630}}>
                                <div>
                                    <div>
                                        <h6><small><Trans>+de</Trans></small> 80</h6>
                                        <p>
                                          <Trans>
                                            escritórios parceiros
                                          </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bullet-fake'></div>
                            <div className='content-bullet-float'>
                                <div className='bullet'>
                                    <div>
                                        <div>
                                            <h6><small><Trans>+de</Trans></small> 5700</h6>
                                            <p>
                                              <Trans>
                                                créditos
                                              </Trans>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bullet'>
                                    <div>
                                        <div>
                                            <h6><small><Trans>+de</Trans></small> 80</h6>
                                            <p>
                                              <Trans>
                                                escritórios parceiros
                                              </Trans>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="responsive-img fit-img wow fadeInLeft text-center">
                                <img src={activeLng !== 'en' ? "/assets/img/foto_servicing.jpg" : "/assets/img/foto_servicing_en.jpg"} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="featured-item featured-item-servicing text-left wow fadeInRight">
                                <div className="title text-uppercase">
                                    <h4><Trans>PLATAFORMA DE SERVIÇOS ROBUSTA E ESCALÁVEL</Trans></h4>
                                </div>
                                <div className="desc">
                                    <ul className="ul-servicing">
                                        <li style={{fontSize: 17}}><Trans>Equipe de 180 profissionais experientes trabalhando em conjunto;</Trans></li>
                                        <li style={{fontSize: 17}}><Trans>Conexão com escritórios de advocacia em todo o país;</Trans></li>
                                        <li style={{fontSize: 17}}><Trans>Gerenciamento de processos e propriedades imobiliárias.</Trans></li>
                                        <Distressed />
                                    </ul>
                                </div>
                            </div>
                            <div className="featured-item featured-item-servicing text-left wow fadeInRight">
                                <div className="title text-uppercase">
                                    <h4><Trans>EQUIPE DE GESTÃO ESPECIALIZADA</Trans></h4>
                                </div>
                                <div className="desc">
                                    <ul className="ul-servicing">
                                        <li style={{fontSize: 17}}><Trans>Equipe multidisciplinar com experiência em diferentes classes de ativos distressed;</Trans></li>
                                        <li style={{fontSize: 17}}><Trans>Habilidade comprovada em buscar e executar acordos;</Trans></li>
                                        <li style={{fontSize: 17}}><Trans>Investigação de ativos no Brasil e no exterior.</Trans></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="featured-item featured-item-servicing text-left wow fadeInRight">
                                <div className="title text-uppercase">
                                    <h4><Trans>FOCO CONTÍNUO EM INOVAÇÃO E TECNOLOGIA</Trans></h4>
                                </div>
                                <div className="desc">
                                    <ul className="ul-servicing">
                                        <li style={{fontSize: 17}}><Trans>Tecnologia de ponta para garantir e fornecer os melhores resultados;</Trans></li>
                                        <MonitoringServicing />
                                        <li style={{fontSize: 17}}><Trans>Plataforma digital proprietária que centraliza todas as informações relevantes para o credor (dados de devedores, empréstimos, garantias, ações judiciais etc);</Trans></li>
                                        <li style={{fontSize: 17}}><Trans>Robôs proprietários para mapear centenas de bancos de dados públicos e privados e cruzam as informações para otimizar o esforço de cobrança.</Trans></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

