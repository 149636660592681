import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Main from './pages/Main';
import Home from './pages/Home';
import JiveDistressedFimIiCpIe from './pages/JiveDistressedFimIiCpIe';
import JiveDistressedFimIiiCp from './pages/JiveDistressedFimIiiCp';
import JiveDistressedFimCpIe from './pages/JiveDistressedFimCpIe';
import VendaSeuAtivo from './pages/VendaSeuAtivo';
import CanalDeDenuncias from './pages/CanalDeDenuncias';
import NoticiasDaJive from './pages/NoticiasDaJive';
// import NoticiasDoMercado from './pages/NoticiasDoMercado';
import NoticiaInterna from './pages/NoticiaInterna';
import Empresa from './pages/Empresa';
// import Historia from './pages/Historia';
import Equipe from './pages/Equipe';
// import Documentos from './pages/Documentos';
import Compliance from './pages/Compliance';
import Servicing from './pages/Servicing';
// import EstrategiaDireitosCreditoriosPrecatorios from './pages/EstrategiaDireitosCreditoriosPrecatorios';
// import CreditoEstruturadoSpecialSits from './pages/CreditoEstruturadoSpecialSits';
// import ImoveisCreditosImobiliariosDistressed from './pages/ImoveisCreditosImobiliariosDistressed';
// import SpecialOpportunities from './pages/SpecialOpportunities';
// import PortfolioCreditosInadimplidos from './pages/PortfolioCreditosInadimplidos';
//import CorporateSingleNames from './pages/CorporateSingleNames';
import Curriculos from './pages/Curriculos';
import Oportunidades from './pages/Oportunidades';
import Eventos from './pages/Eventos';
import Disclaimer from './pages/Disclaimer';
import Contato from './pages/Contato';
import Estrategias from './pages/Estrategias';

export default function Routes() {
  return (
    <BrowserRouter>
      <Route path="/" exact component={Home} />
      <Route path="/dev/:id" component={Main} />
      <Route path="/jive-distressed-fim-ii-cp-ie" component={JiveDistressedFimIiCpIe} />
      <Route path="/jive-distressed-fim-iii-cp" component={JiveDistressedFimIiiCp} />
      <Route path="/jive-distressed-fim-cp-ie" component={JiveDistressedFimCpIe} />
      <Route path="/venda-seu-ativo" component={VendaSeuAtivo} />
      <Route path="/canal-de-denuncias" component={CanalDeDenuncias} />
      <Route path="/jive-na-midia" component={NoticiasDaJive} />
      {/* <Route path="/noticias-do-mercado" component={NoticiasDoMercado} /> */}
      <Route path="/noticia-interna" component={NoticiaInterna} />
      <Route path="/a-empresa" component={Empresa} />
      {/* <Route path="/historia" component={Historia} /> */}
      <Route path="/equipe-de-gestao" component={Equipe} />
      {/* <Route path="/documentos" component={Documentos} /> */}
      <Route path="/compliance" component={Compliance} />
      <Route path="/servicing" component={Servicing} />
      {/* <Route path="/estrategia-direitos-creditorios-precatorios" component={EstrategiaDireitosCreditoriosPrecatorios} />
      <Route path="/estrategia-credito-estruturado-special-sits" component={CreditoEstruturadoSpecialSits} />
      <Route path="/estrategia-imoveis-creditos-imobiliarios-distressed" component={ImoveisCreditosImobiliariosDistressed} /> */}
      {/* <Route path="/estrategia-special-opportunities" component={SpecialOpportunities} /> */}
      {/* <Route path="/estrategia-portfolio-creditos-inadimplidos" component={PortfolioCreditosInadimplidos} /> */}
      {/* <Route path="/estrategia-corporate-single-names" component={CorporateSingleNames} /> */}
      <Route path="/curriculos" component={Curriculos} />
      <Route path="/oportunidades" component={Oportunidades} />
      <Route path="/eventos" component={Eventos} />
      <Route path="/disclaimer" component={Disclaimer} />
      <Route path="/contato" component={Contato} />
      <Route path="/estrategias" component={Estrategias} />
    </BrowserRouter>
  );
}
