import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import modalContext from '../../context/modal-context';

import './style.css';

function CoockieModal() {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [inputValue, setInputValue] = useState(false);
  const [localStorage, setLocalStorage] = useState({});

  const { showSettingsModal, setSettingsShowModal } = useContext(modalContext);

  useEffect(() => {
    if (window.localStorage.getItem('coockies')) {
      const localStorageData = JSON.parse(window.localStorage.getItem('coockies'));

      setShow(false);
      setLocalStorage(localStorageData);
    } else {
      setShow(true);
    }
  }, [show]);

  useEffect(() => {
    if (!window.localStorage.getItem('coockies')) {
      setShow(!showSettingsModal);
    }
  }, [showSettingsModal]);

  useEffect(() => {
    if (localStorage.hasOwnProperty('analytics')) {
      setInputValue(localStorage.analytics);
    }
  }, [localStorage]);

  const handleActiveTab = number => setActiveTab(number);

  const handleAcceptAllCockies = () => {
    setSettingsShowModal(false);
    setShow(false);

    window.localStorage.setItem('coockies', JSON.stringify({
      default: true,
      analytics: true
    }));
  };

  const handleChangeInput = (event) => {
    setInputValue(event.target.checked);
  };

  const handleSetCoockies = () => {
    setSettingsShowModal(false);
    setShow(false);

    window.localStorage.setItem('coockies', JSON.stringify({
      default: true,
      analytics: inputValue
    }));
  };

  return (
    <>
      {
        localStorage?.analytics && (
          <script>
            {
              (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', 'GTM-M2GQXJ9')
            }
          </script>
        )
      }

      {
        show && (
          <div className="coockieModal">
            <p>
              <Trans>Clicando em Aceito todos os cookies, você aceita nossa política de cookies com objetivo de melhorar sua navegação. Conheça nossa política e</Trans>
              <span onClick={() => setSettingsShowModal(!showSettingsModal)}><Trans>configure seus cookies</Trans></span>
            </p>
      
            <button className='coockies' onClick={handleAcceptAllCockies}><Trans>Aceitar todos os cookies</Trans></button>
          </div>
        )
      }

      {
        showSettingsModal && (
          <div className='coockieSettingsModal'>
            <div className='coockieSettingsModal__content'>
              <header>
                <button title='Fechar' onClick={() => setSettingsShowModal(!showSettingsModal)}>&times;</button>

                <div>
                  <button onClick={() => handleActiveTab(1)} className={activeTab === 1 ? 'active' : ''}><Trans>NOSSA POLÍTICA DE COOKIES</Trans></button>
                  <button onClick={() => handleActiveTab(2)} className={activeTab === 2 ? 'active' : ''}><Trans>MUDE SUA CONFIGURAÇÃO</Trans></button>
                </div>
              </header>

              <div className={activeTab === 1 ? 'active' : ''}>
                <p>
                  <Trans>Utilizamos cookies para garantir a melhor experiência. Alguns são essenciais para o funcionamento, como o login, e não podem ser desativados. Temos também cookies para melhorar a performance, oferecer conteúdo personalizado, recursos nas redes sociais e análise de tráfego. Estes cookies podem incluir cookies de terceiros.</Trans>
                </p>

                <button className='coockies' onClick={handleAcceptAllCockies}><Trans>Aceitar todos os cookies</Trans></button>
              </div>

              <div className={activeTab === 2 ? 'active' : ''}>
                <div className='checkbox'>
                  <input type='checkbox' checked={true} disabled={true} />
                  <p>
                    <span><Trans>Cookies Funcionais</Trans></span>
                    <Trans>Estes cookies são essenciais para o funcionamento do website. Sem estes, os nossos websites não funcionariam da melhor forma. Estes são armazenados temporariamente como informação de login e expiram assim que o navegador é fechado.</Trans>
                  </p>
                </div>

                <div className='checkbox'>
                  <input type='checkbox' checked={inputValue} onChange={handleChangeInput} />
                  <p>
                    <span><Trans>Cookies Analíticos</Trans></span>
                    <Trans>A informação fornecida pelos cookies analíticos nos permite analisar padrões do comportamento do visitante, e essas informações são utilizadas para melhorar a experiência em geral ou identificar áreas do website que necessitam de manutenção. Esta informação é anônima (não pode ser usada para identificá-lo e não contêm informação pessoal como nome e endereço de e-mail) e é apenas utilizada para fins estatísticos. Cookies comportamentais são semelhantes aos analíticos e registram a sua visita ao website e usam a informação para fornecer conteúdo de acordo com os seus interesses.</Trans>
                  </p>
                </div>

                <div className='buttons'>
                  <button className='coockies outline' onClick={handleAcceptAllCockies}><Trans>Aceitar todos os cookies</Trans></button>
                  <button className='coockies' onClick={handleSetCoockies}><Trans>salvar configurações</Trans></button>
                </div>
              </div>
            </div>

            <div onClick={() => setSettingsShowModal(!showSettingsModal)} className='coockieSettingsModal__overlay'></div>
          </div>
        )
      }
    </>
  );
}

export default CoockieModal;
