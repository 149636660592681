import React from 'react';
import './Estrategias.css';
import Layout from '../template/Layout';
import { Link } from 'react-router-dom';
import gavelImg from '../assets/images/gavel.png'
import SubHeader from "../components/SubHeader";
import {useTranslation} from "react-i18next";
import {Trans} from "react-i18next";
import {getLng} from "../helpers";
import { Helmet } from 'react-helmet';


export default function Estrategias(props) {
    const { t,  i18n } = useTranslation();

    const activeLng = getLng(i18n)

  return (
    <Layout>
         <Helmet>
          <title>{t('ESTRATÉGIAS')}</title>
        </Helmet>

        <section className="body-content" id="page-estrategias">
            <section className="page-title background-title dark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-uppercase"><Trans>ESTRATÉGIAS</Trans></h3>
                        </div>
                    </div>
                </div>
            </section>
            <SubHeader nameImage='frase_estrategia.jpg' />
            {/* <div className="page-content p-top-0 p-bot-0">
                <div className="container content">
                    <div className='content-bullet'>
                        <div className='bullet'>
                            <div>
                                <div>
                                    <h6><Trans>390M+</Trans></h6>
                                    <p>
                                        <Trans>
                                            em saldo devedor agregado a Corporate NPL
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet bullet-mobile' style={{top: 210}}>
                            <div>
                                <div>
                                    <h6><Trans>20M+</Trans></h6>
                                    <p>
                                        <Trans>
                                            em valor de face para Precatórios
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet-fake'></div>
                        <div className='bullet'>
                            <div>
                                <div>
                                    <h6><Trans>100M+</Trans></h6>
                                    <p>
                                        <Trans>
                                            em valor agregado para imóveis
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet bullet-mobile' style={{top: 630}}>
                            <div>
                                <div>
                                    <h6><Trans>+20M</Trans></h6>
                                    <p>
                                        <Trans>
                                            em valor de face para outros ativos
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet-fake'></div>
                        <div className='content-bullet-float'>
                            <div className='bullet'>
                                <div>
                                    <div>
                                        <h6><Trans>20M+</Trans></h6>
                                        <p>
                                            <Trans>
                                                em valor de face para Precatórios
                                            </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bullet'>
                                <div>
                                    <div>
                                        <h6><Trans>20M+</Trans></h6>
                                        <p>
                                            <Trans>
                                                em valor de face para outros ativos
                                            </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="featured-item feature-border-box text-left m-bot-50">
                                <div className="icon">
                                    <img src={gavelImg} width={30} alt=''/>
                                </div>
                                <div className="title text-uppercase">
                                    <h4><Trans>DIREITOS CREDITÓRIOS E PRECATÓRIOS</Trans></h4>
                                </div>
                                <div style={{fontSize: 17}} className="desc">
                                  <Trans>
                                    Investimentos em direitos discutidos de ações judiciais, procedimentos administrativos e arbitragens.  Nosso foco são direitos contra a administração pública, incluindo precatórios e pré-precatórios, e contra grandes empresas públicas e privadas.
                                  </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="featured-item feature-border-box text-left m-bot-50">
                                <div className="icon">
                                    <i className="icon-ecommerce_banknotes"></i>
                                </div>
                                <div className="title text-uppercase">
                                    <h4><Trans>CRÉDITO ESTRUTURADO E <i>SPECIAL SITS</i></Trans></h4>
                                </div>
                                <div style={{fontSize: 17}} className="desc">
                                  <Trans>
                                    Investimentos em operações de crédito estruturado com alto retorno potencial, como financiamento de empresas em recuperação judicial (<i>DIP Financing</i>), financiamento lastreado em imóveis e outros ativos, conversão de dívida em participação, desalavancagem e reestruturação financeira e operacional.
                                  </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="featured-item feature-border-box text-left m-bot-50">
                                <div className="icon">
                                    <i className="icon-apartment_building"></i>
                                </div>
                                <div className="title text-uppercase">
                                    <h4><Trans>IMÓVEIS E CRÉDITOS IMOBILIÁRIOS <i>DISTRESSED</i></Trans></h4>
                                </div>
                                <div style={{fontSize: 17}} className="desc">
                                  <Trans>
                                    Investimentos em ativos imobiliários urbanos ou rurais cujos proprietários possuam problemas de crédito ou liquidez ou estejam em processo de recuperação judicial, falência ou outros similares.  Também adquirimos imóveis com pendências jurídicas ou de posse, além de estoques de imóveis de construtoras ou entidades governamentais, ativos não operacionais de empresas e bens não de uso próprio (BNDU) de instituições financeiras.
                                  </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="featured-item feature-border-box text-left m-bot-50">
                                <div className="icon">
                                    <i className="icon-ecommerce_graph_increase"></i>
                                </div>
                                <div className="title text-uppercase">
                                    <h4><Trans>PORTFÓLIO DE CRÉDITOS INADIMPLIDOS</Trans></h4>
                                </div>
                                <div style={{fontSize: 17}} className="desc">
                                  <Trans>
                                    Investimentos em carteiras de créditos contra empresas de médio porte vencidos e não pagos (“NPLs”), com ou sem garantias, incluindo créditos contra empresas em situação de falência ou recuperação judicial.
                                  </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="featured-item feature-border-box text-left">
                                <div className="icon">
                                    <i className="icon-caution"></i>
                                </div>
                                <div className="title text-uppercase">
                                    <h4><Trans><i>CORPORATE SINGLE-NAMES</i></Trans></h4>
                                </div>
                                <div style={{fontSize: 17}} className="desc">
                                  <Trans>
                                    Investimentos em créditos contra grandes empresas vencidos e não pagos de alta complexidade, com ou sem garantias, contra empresas públicas ou privadas.
                                  </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="featured-item feature-border-box text-left m-bot-50">
                                <div className="icon">
                                    <i className="icon-apartment_building"></i>
                                </div>
                                <div className="title text-uppercase">
                                    <h4><Trans> <i>LITIGATION FINANCE</i></Trans></h4>
                                </div>
                                <div style={{fontSize: 17}} className="desc">
                                  <Trans>
                                    Investimentos em financiamento de litígios ( <i> third-party funding </i>). Nosso foco é financiar disputas arbitrais e judiciais, nacionais e internacionais, envolvendo tanto <i>players</i> do setor privado quanto entes públicos, com grandes chances de êxito do financiado. A partir do trabalho de uma equipe experiente em litígios complexos, maximizamos o valor do litígio para todos os <i>stakeholders</i>.
                                  </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                    { activeLng !== 'en' && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="promo-box ativo theme-bg  round-5 m-bot-50">
                                    <div className="promo-info">
                                        <h3 className="light-txt"><Trans>VENDA SEU ATIVO E/OU FINANCIE SEU LITÍGIO</Trans></h3>
                                        <span className="light-txt text-uppercase m-top-0"><Trans>CONHEÇA AS SOLUÇÕES QUE A JIVE POSSUI PARA VOCÊ MONETIZAR O SEU CRÉDITO, ATIVO ADMINISTRATIVO, JUDICIAL OU ARBITRAL OU IMÓVEL EM SITUAÇÃO COMPLEXA, BEM COMO PARA FINANCIAR OS CUSTOS DO SEU LITÍGIO
</Trans></span>
                                    </div>
                                    <div className="promo-btn">
                                        <a href="/venda-seu-ativo" className="btn btn-medium btn-dark-solid btn-rounded  btn-transparent light-txt  text-uppercase"><Trans>VER MAIS</Trans></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    </Layout>
  )
}

