import React from 'react';
import Loader from './Loader';
import Cabecalho from './Cabecalho';
import Rodape from './Rodape';

export default function Layout(props) {


    return (
      <div>
       {/*<Loader />*/}
       <Cabecalho />
       {props.children}
       <Rodape />
      </div>
    );

}

