import React from 'react';
import './CanalDeDenuncias.css';
import Layout from '../template/Layout';

export default function CanalDeDenuncias(props) {
  
  return (
    <Layout>
        <section className="body-content" id="page-canal-de-denuncias">
            <section className="page-title background-title dark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-uppercase">Canal de denúncias </h3>
                        </div>
                    </div>
                </div>
            </section>
            <div className="page-content">  
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Este Canal de Denúncias oferece a oportunidade de reportar informações relativas à violações de qualquer natureza, bem como suspeitas fundamentadas de violações. Tanto Colaboradores, quanto Terceiros, podem usar esse meio de comunicação.</p>
                            <p>A análise do conteúdo das denúncias é realizada exclusivamente pela Jive e o anonimato é garantido ao denunciante. As informações fornecidas serão tratadas em estrita confidencialidade e apenas compartilhadas para a devida identificação e análise do caso. Mesmo garantindo o anonimato, a Jive encoraja seus Colaboradores e a qualquer outro denunciante a identificarem-se durante o processo, pois crê na independência e garantia de não retaliação.</p>
                            <p>A informação fornecida poderá motivar o início de investigações internas, feitas por equipe especializada, bem como de investigações por autoridades públicas e a tomada de medidas judiciais cabíveis. A disseminação consciente de informação falsa terá duras consequências.</p>
                            <p className="box-atencao">ATENÇÃO: Este canal de denúncias NÃO é um canal de emergência. Não use este canal para denunciar ameaças iminentes à vida. Nestes casos, somente a autoridade policial local poderá interceder. </p>
                            <p>Faça sua denúncia utilizando o formulário abaixo:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">

                            <form className="contact-comments m-top-0 js-Mailer" method="post">

                                <div className="row row-form">
                                    
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="form-field-titulo">TÍTULO*</label>
                                            <input type="text" name="titulo" id="form-field-titulo" className="form-control" maxLength="100" required=""/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="descricao">Descrição*</label>
                                            <textarea className="form-control" name="descricao" required=""></textarea>
                                        </div>
                                    </div> 
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="form-field-nome">Nome</label>
                                            <input type="text" name="nome" id="form-field-nome" className="form-control" maxLength="100" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <label for="form-field-email">E-mail</label>
                                            <input type="email" name="email" id="form-field-email" className="form-control" maxLength="100" required data-error="Invalid email address!" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>  
                                    <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                    <p>* Campos obrigatórios </p>
                                    </div>
                                    <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                        <button type="submit" className="btn btn-small btn-dark-solid ">ENVIAR</button>
                                    </div>                         

                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
} 

