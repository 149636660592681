import React from 'react';
import './Equipe.css';
import Layout from '../template/Layout';
import SubHeader from "../components/SubHeader";
import {useTranslation, Trans} from "react-i18next";
import { Helmet } from 'react-helmet';

export default function Equipe(props) {
  const { t } = useTranslation();

  return (
    <Layout>
        <Helmet>
          <title>{t('MANAGING PARTNERS')}</title>
        </Helmet>

        <section className="body-content" id="page-equipe">
            <section className="page-title background-title dark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-uppercase"><Trans>MANAGING PARTNERS</Trans></h3>
                        </div>
                    </div>
                </div>
            </section>
            <SubHeader nameImage='frase_equipe.jpg' />
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                                <h4 className="m-bot-0"><Trans>Alexandre Cruz | <i>Managing Partner</i></Trans></h4>
                            <p style={{fontSize: 17}}>
                                <Trans>Sócio-fundador, é responsável pela estratégia, monitoramento da concorrência e tecnologia da Jive. Começou a investir em ativos estressados quando passou a adquirir estruturas locais e créditos não performados de subsidiárias de clientes internacionais da Neointelligence, uma empresa de contabilidade e <i>outsourcing</i> financeiro para clientes corporativos da qual foi sócio. Antes disso, Alexandre trabalhou na Ernst & Young e no banco Santander. É formado em economia pela Universidade de São Paulo (USP) e em direito pela PUC-SP, com MBA pela Fundação Getúlio Vargas em São Paulo.</Trans>
                            </p>
                                <h4 className="m-bot-0"><Trans>Guilherme Ferreira | <i>Managing Partner</i></Trans></h4>
                            <p style={{fontSize: 17}}>
                              <Trans>
                                Sócio responsável pelas áreas de originação e estruturação de investimentos, Guilherme ingressou na Jive depois de 4 anos no Lehman Brothers, onde trabalhou inicialmente na mesa de renda fixa da América Latina em Nova Iorque e depois como liquidante da operação do Lehman Brothers na América Latina. Antes de ingressar no mercado financeiro, trabalhou como advogado com foco em direito empresarial por sete anos no Brasil e em Nova Iorque. É formado em direito pela Universidade de São Paulo (USP) e é mestre em direito (LLM) pela <i>Columbia University</i> em Nova Iorque, onde recebeu o <i>James Kent Awarda</i> por graduar-se entre os melhores de sua turma.
                              </Trans>
                            </p>
                            <h4 className="m-bot-0"><Trans>Mateus Tessler | <i>Managing Partner</i></Trans></h4>
                            <p style={{fontSize: 17}}>
                              <Trans>
                                Sócio responsável pela área de <i>Corporate</i> NPL, antes de entrar na Jive Mateus trabalhou nas áreas de fusões e aquisições, consultoria em finanças corporativas na Deloitte, <i>Private Equity</i> e <i>Venture Capital</i> como gestor de recursos na Invest Tech e DLM Invista. Ao longo da carreira, participou ativamente de cerca de 30 processos de M&A e acompanhou 12 investimentos de Private Equity. Advogado formado pelo Mackenzie, com <i>pós-graduado em finanças</i> pelo INSPER.
                              </Trans>
                            </p>
                            <h4 className="m-bot-0"><Trans>Diego Fonseca | <i>Managing Partner</i></Trans></h4>
                            <p style={{fontSize: 17}}>
                                <Trans>
                                    Sócio responsável pelas áreas de <i>Real Estate, Investor Relations</i>, Finanças e Operações, Diego ingressou na Jive após 11 anos de atuação no Credit Suisse Hedging-Griffo (CSHG). Como <i>Managing Director</i> da CSHG foi COO e <i>Head of Products</i> para o <i>Private Banking</i>. Antes disso, atuou no <i>Corporate & Investment Bank</i> do ABN AMRO Bank no Brasil por cinco anos, após ter trabalhado na Rio Bravo Investimentos como analista de finanças estruturadas. É bacharel em Administração de Empresas pela EAESP-FGV e em Direito pela Universidade de São Paulo (USP). Possui MBA em <i>Banking</i> pela Fundação Dom Cabral e pós-graduação em Direito Tributário pela EDESP-FGV.
                                </Trans>
                            </p>
                            <h4 className="m-bot-0"><Trans>Marcelo Martins | <i>Managing Partner</i></Trans></h4>
                            <p style={{fontSize: 17}}>
                              <Trans>
                                Sócio responsável pelo desenvolvimento de parcerias internacionais e co-investimentos. Antes de entrar na Jive, Marcelo trabalhou por 12 anos no segmento de <i>Private Equity</i> e Banco de Investimento, com foco na originação, execução e monitoramento de transações nos Estados Unidos, Ásia, Europa e no Brasil, tendo trabalhado na Axiom Capital Management, GP Investimentos e no banco Goldman Sachs. Ele também co-fundou a Spring Wireless, uma empresa de tecnologia que teve como investidores a Intel Capital, Goldman Sachs e o banco UBS. Tem MBA pela <i>Harvard Business School</i> e diploma de engenharia elétrica pela <i>Karlsruhe University</i> (Alemanha) e UNICAMP (Brasil).
                              </Trans>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

