import React, {useEffect, useState} from 'react';
import './Disclaimer.css';
import Layout from '../template/Layout';
import {Trans} from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Disclaimer(props) {
    const [customClass] = useState('custom-header');
    useEffect(() => {
        document.body.classList.add(customClass);
        return () => {
            document.body.classList.remove(customClass);
        }
    },[customClass]);

  return (
    <Layout>
        <Helmet>
          <title>Disclaimer</title>
        </Helmet>

        <section className="page-title title-margin">
            <div className="container">
                <h4 className="text-uppercase">Disclaimer</h4>
            </div>
        </section>
        <section className="body-content" id="page-disclaimer">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                              <Trans>
                                Este conteúdo foi preparado pela Jive Investments para fins meramente informativos e não deve ser entendido como análise de valor mobiliário, material promocional, solicitação de compra ou venda, oferta ou recomendação de qualquer ativo financeiro ou investimento. As referências aos produtos e serviços são meramente indicativas e não consideram os objetivos de investimento, a situação financeira ou necessidades individuais e particulares do investidor.
                              </Trans>
                            </p>
                            <p>
                              <Trans>
                                Recomendamos uma consulta ao seu assessor de investimento para análise específica e personalizada antes de sua decisão sobre quais os produtos e serviços adequados à sua situação e objetivos. Adicionalmente, recomendamos consulta a profissionais especializados e independentes antes de decidir sobre investimentos no exterior, investimentos imobiliários, tributação e sucessão. A Jive Investments não se responsabiliza por decisões do cliente acerca destes temas nem por ato ou fato dos profissionais por ele consultados. As informações veiculadas, valores e taxas são referentes às datas e condições indicadas no material e não serão atualizadas.
                              </Trans>
                            </p>
                            <p>
                              <Trans>
                                  A Jive Investments se reserva o direito de alterar qualquer condição, valor e taxas mencionados neste material a qualquer tempo e sem a necessidade de comunicação ou notificação. A Jive Investments poderá estabelecer, a seu exclusivo critério, requisitos e procedimentos específicos para abertura de contas e acesso a produtos. Os produtos e serviços mencionados estão sujeitos à disponibilidade e condições estabelecidas pela Jive Investments e serão prestados conforme autorizações legais concedidas às diferentes empresas integrantes do grupo.
                              </Trans>
                            </p>
                            <p>
                              <Trans>
                                 O investimento em determinados ativos financeiros pode sujeitar o investidor a significativas perdas patrimoniais. Ao investidor cabe a responsabilidade de informar-se sobre todos os riscos previamente à tomada de decisão sobre investimentos. Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. É recomendada a leitura cuidadosa do prospecto e regulamento do fundo de investimento pelo investidor ao aplicar seus recursos.
                              </Trans>
                            </p>
                            <p>
                              <Trans>
                            <strong>Este material não pode ser copiado, reproduzido ou distribuído sem a expressa concordância da Jive Investments.</strong>
                              </Trans>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

