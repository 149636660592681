import React, {useEffect, useState} from 'react';
import './Curriculos.css';
import Layout from '../template/Layout';
import api from "../services/api";
import {useTranslation, Trans} from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Curriculos(props) {
    const [resultMessage, setResultMessage] = useState(null);
    const {  t } = useTranslation();

    const [customClass] = useState('custom-header');
    useEffect(() => {
        document.body.classList.add(customClass);
        return () => {
            document.body.classList.remove(customClass);
        }
    },[customClass]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        document.getElementById('submit-curriculo').disabled = true;
        const { elements } = event.target;
        const name = elements.name.value;
        const email = elements.email.value;
        const city = elements.city.value;
        const state = elements.state.value;
        const phone = elements.phone.value;
        const curriculum = elements.curriculum.files[0];
        const form = new FormData()
        form.append('name', name);
        form.append('email', email);
        form.append('city', city);
        form.append('state', state);
        form.append('phone', phone);
        form.append('curriculum', curriculum);
        const result = await api.post('/send-emails/curriculum', form);
        setResultMessage('Email enviado com sucesso!');
        document.getElementById("form-curriculo").reset();
        document.getElementById('submit-curriculo').disabled = false;
        setTimeout(() => {
            setResultMessage(null)
        }, 5000)
    }

  return (
    <Layout>
        <Helmet>
            <title>{t('CurriculosTitulo')}</title>
        </Helmet>
        <section className="page-title title-margin">
            <div className="container">
                <h4 className="text-uppercase"><Trans>Currículos</Trans></h4>
            </div>
        </section>
        <section className="body-content" id="page-curriculos">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <h3 className="title-send text-uppercase m-bot-0 text-center">
                            <Trans i18nKey='multiline'>
                              Envie seu currículo para nossa equipe <br/> de Recrutamento e Seleção
                            </Trans>
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
            <div className="page-content p-top-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">

                            <form id='form-curriculo' className="contact-comments m-top-0" onSubmit={handleSubmit} method="post">

                                { resultMessage && (<p className='alert alert-success'>
                                    {resultMessage}
                                </p>)
                                }

                                <div className="row row-form">

                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="form-field-nome"><Trans>Nome *</Trans></label>
                                            <input type="text" name="name" id="form-field-email" className="form-control" maxLength="100" required data-error="Nome inválido!" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="form-field-email"><Trans>E-mail *</Trans></label>
                                            <input type="email" name="email" id="form-field-email" className="form-control" maxLength="100" required data-error="Email inválido!" />
                                            <div className="help-block with-errors"></div>
                                        </div>

                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="form-field-phone"><Trans>Telefone</Trans></label>
                                            <input type="text" name="phone" id="form-field-phone" className="form-control" maxLength="100" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="form-field-cidade"><Trans>Cidade</Trans></label>
                                            <input type="text" name="city" id="form-field-cidade" className="form-control" maxLength="100" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="form-field-estado"><Trans>Estado</Trans></label>
                                            <input type="text" name="state" id="form-field-estado" className="form-control" maxLength="100" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="form-field-curriculo"><Trans>CV *</Trans></label>
                                            <input type="file" name="curriculum" id="form-field-curriculo" className="form-control" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group m-bot-0 col-md-12 col-sm-12 privace-policy">
                                        <input type="checkbox" name="privace-policy" id="form-field-privace" required />
                                        <label for="form-field-privace">
                                            <Trans>Estou de acordo com as</Trans>
                                            <a href="https://jive-site-prod.s3.amazonaws.com/fcc198a1-3b0c-4668-aee1-7034465eec27" target="_blank" rel="noopener noreferrer">
                                                <Trans>políticas de privacidade, termos de uso da Jive Investments</Trans>
                                            </a>
                                        </label>
                                    </div>
                                    <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                        <p className="m-bot-0"><Trans>* Campos obrigatórios</Trans></p>
                                    </div>
                                    <div className="m-bot-0 col-md-12 col-sm-12" style={{marginTop: 8}}>
                                        <p>
                                          <Trans>
                                            * ATENÇÃO O envio do formulário poderá levar alguns segundos dependendo do tamanho do arquivo de currículo enviado. Pedimos que aguarde até a validação do envio.
                                          </Trans>
                                        </p>
                                    </div>
                                    <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                        <button id='submit-curriculo' type="submit" className="btn btn-small btn-dark-solid "><Trans>ENVIAR</Trans></button>
                                    </div>

                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

