import i18n from 'i18next';
import {initReactI18next, Trans} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import React from "react";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: {
                translations: {

                    // TOPO

                    "Sobre": "About",
                    "A empresa": "The firm",
                    "MANAGING PARTNERS": "MANAGING PARTNERS",
                    "Fundos": "Funds",
                    "Estratégias": "Strategies",
                    "Jive na mídia": "Jive in the media",
                    "Eventos": "Events",
                    "Carreiras": "Careers",
                    "Currículos": "Send your resume",
                    "CurriculosTitulo": "Resume",
                    "Oportunidades": "Job opportunities",
                    "Contato": "Contact",

                    // HOME

                    "JIVE E MAUÁ AGORA SÃO JIVEMAUÁ.": "JIVE AND MAUÁ ARE NOW JIVEMAUÁ.",
                    "EM BREVE, EM NOVO SITE": "COMING SOON A NEW WEBSITE.",
                    "JiveMauá é uma gestora de investimentos alternativos que nasceu da fusão da <i>Jive Investments</i> com a <i>Mauá Capital.</i>": "JiveMauá is an asset management company that was formed by merging Jive Investments and Mauá Capital.",
                    "Com mais de 18 anos de experiências combinadas, somos uma gestora de crédito que possui know-how para originar e gerir de forma ativa as próprias operações, com profissionais experientes em avaliar riscos e oportunidades.": "With over 18 years of combined experience, we are a credit manager with the expertise to originate and actively manage our own operations. We employ professionals experienced in assessing risks and opportunities.",    
                    "A JiveMauá tem hoje mais de R$18,6 bilhões sob gestão e mais de 250 mil investidores que confiam em nossa estratégia.": "JiveMauá currently manages over R$18.6 billion in assets and has the trust of more than 250,000 investors in our strategy.",
                    "SAIBA MAIS": "LEARN MORE",
                    "Notícias": "NEWS",
                    "Confira as últimas notícias que separamos sobre a Jive e o Mercado": "Check out the latest news about Jive and the financial market",
                    "VER MAIS": "Read more",
                    "MISSÃO": "MISSION",
                    "Criar valor transformando riscos em oportunidades de alto retorno para investidores, acionistas e sociedade": "Create value by transforming risks in high return opportunities for our investors, shareholders and society.",
                    "VISÃO": "VISION",
                    vision: "Be the most respected alternative <br/> investments’ firm in Latin America.",

                    // RODAPÉ

                    "ENDEREÇO": "ADDRESS",
                    addressJive: "Av. Brigadeiro Faria Lima, 1485 <br/> North Tower - 17º floor – ZIP CODE: 01452-002 <br/> São Paulo | SP - Brazil",
                    "TELEFONE": "PHONE NUMBER",
                    "CANAL DE DENÚNCIAS": "WHISTLEBLOWING CHANNEL",
                    "Envie a sua denúncia aqui": "Report your concern here.",
                    "Conheça nossa página de <1><i>compliance</i></1>": "Learn more about our <1>Compliance</1> page",
                    "SIGA-NOS": "FOLLOW US",
                    "Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. Leia o prospecto e o regulamento antes de investir. © Jive Investments - Todos os direitos reservados - <1><i>Disclaimer</i></1>": "Investment funds are not guaranteed by the Fund Administrator, Investment Manager, insurance mechanisms or the Fundo Garantidor de Crédito (FGC – Brazil’s Credit Guarantee Fund).  Past performance does not guarantee future results. An investor should consider the Fund’s investment objectives, risks, charges and expenses carefully before investing. This and other important information about the Fund can be found in the Fund’s private placement memorandum and/or bylaws.  Please read the private placement memorandum and/or bylaws carefully before investing.  © Jive Investments – All rights deserved - <1>Disclaimer</1>",
                    "Configurações de cookies": "Cookie settings",

                    // THE FIRM

                    "A Empresa": "THE FIRM",
                    "A Jive Investments é uma plataforma integrada de gestão de ativos alternativos com foco na originação, aquisição e recuperação de créditos inadimplidos (“NPL”), imóveis em situações complexas, ativos judiciais e outros ativos <i>distressed</i>.  Pioneira no investimento em ativos <i>distressed</i> no Brasil e maior <i>player</i> independente do mercado, a Jive possui um processo de recuperação de crédito totalmente integrado, incluindo a gestão de processos judiciais e administrativos de cobrança e o gerenciamento de imóveis e outros ativos físicos recuperados, dentro de uma plataforma única e escalável com utilização intensiva de tecnologia para a localização, precificação e recuperação de ativos.": "Jive Investments is an integrated alternative assets platform that focuses on the origination, acquisition and recovery of non-performing loans (NPLs), distressed real estate properties, legal claims and other distressed assets. Pioneer in the distressed asset business in Brazil, Jive is the largest independent player in the Brazilian financial market that has a completely integrated credit recovery process that includes management of legal claims both on the private and public sector as well as the management of distressed real estate properties. Focusing on servicing distressed assets, Jive created a unique platform that employs high-end technology used specifically in the search, pricing and recovery of distressed assets.",
                    "A Jive investe continuamente em inovação e tecnologia de ponta para oferecer a melhor solução em recuperação de créditos, imóveis e outros ativos <i>distressed</i> para bancos, empresas e demais credores.": "Jive continually invests in innovation and high-end technology to offer the best solution in the recovery of distressed assets for banks, companies and other creditors.",
                    "+de": "+",
                    "investidores no Brasil e no exterior": "investors in Brazil and abroad",
                    "R$ 7,5 Bi": "R$ 7.5 Bi",
                    "em ativos sob gestão (AUM)": "in assets under management (AUM)",
                    "R$ 22 bi": "R$ 22 b",
                    "em valor de face": "in face value",
                    "colaboradores": "professionals",

                    // HISTORY

                    "HISTÓRIA": "HISTORY",
                    "Em 2010, a Jive Investments foi criada para participar do leilão dos ativos do Lehman Brothers no Brasil. Investidores tanto locais quanto internacionais fizeram propostas para o portfólio do Lehman Brothers, mas a Jive apresentou uma proposta mais simples e adquiriu o portfólio com a aprovação da Corte de Falências de Nova Iorque em dezembro de 2010.": "The firm was created in 2010 in order to participate in the auction of Lehman Brother’s credit portfolio in Brazil. Both national and international investors made multiple proposals for the Lehman Brother’s portfolio, but Jive stood out with a well-thought offer and acquired the credits within the New York Bankruptcy Court’s approval process in December 2010.",
                    history2010: "Jive acquires the Lehman Brother’s portfolio for R$ 27 million (3% of face value)",
                    history2011_2014: "15 different investments in NPLs as well as distressed real estate with proprietary capital and proceeds from the Lehman portfolio",
                    "Distribuição do Jive <i>Distressed</i> FIM CP IE (R$ 500 milhões)": "Launching of Jive Distressed FIM (R$ 500 MM)",
                    history2018: "Launching of Jive Distressed FIM II (R$ 1,75 Bi)",
                    history2020: "Launching of Jive Distressed FIM III with R$ 2,2 Bi raised onshore + R$ 1,3 Bi raised offshore + USD 80 MM via IFC",
                    history2021: "1.6x commited capital distributed to investors in Fund I",

                    // THE TEAM
                    "Alexandre Cruz | <i>Managing Partner</i>": "Alexandre Cruz | Managing Partner",
                    "Sócio-fundador, é responsável pela estratégia, monitoramento da concorrência e tecnologia da Jive. Começou a investir em ativos estressados quando passou a adquirir estruturas locais e créditos não performados de subsidiárias de clientes internacionais da Neointelligence, uma empresa de contabilidade e <i>outsourcing</i> financeiro para clientes corporativos da qual foi sócio. Antes disso, Alexandre trabalhou na Ernst & Young e no banco Santander. É formado em economia pela Universidade de São Paulo (USP) e em direito pela PUC-SP, com MBA pela Fundação Getúlio Vargas em São Paulo.": "Alexandre founded Jive in 2010 when he started acquiring non-performing credits from his clients at NEO - a full-service accounting, reporting and compliance servicer with more than 100 skilled professionals - which he founded in 2004 and remains as the controlling shareholder. Previously, he worked at Ernst & Young focused on Corporate Finance from 2002 until 2004. Alexandre holds an Economics Degree from University of São Paulo (1995-2000), a law degree from Pontificia Universidade Catolica (2004-2008) and MBA from Fundação Getúlio Vargas (“FGV”) in São Paulo (2000-2002).",
                    "Guilherme Ferreira | <i>Managing Partner</i>": "Guilherme Ferreira | Managing Partner",
                    "Sócio responsável pelas áreas de originação e estruturação de investimentos, Guilherme ingressou na Jive depois de 4 anos no Lehman Brothers, onde trabalhou inicialmente na mesa de renda fixa da América Latina em Nova Iorque e depois como liquidante da operação do Lehman Brothers na América Latina. Antes de ingressar no mercado financeiro, trabalhou como advogado com foco em direito empresarial por sete anos no Brasil e em Nova Iorque. É formado em direito pela Universidade de São Paulo (USP) e é mestre em direito (LLM) pela <i>Columbia University</i> em Nova Iorque, onde recebeu o <i>James Kent Awarda</i> por graduar-se entre os melhores de sua turma.": "Guilherme joined Jive after four years at Lehman Brothers (2007-2011), where he first worked at the Latin American Fixed Income desk in New York and later as de facto liquidator of the operations of Lehman Brothers in Brazil. Before joining the financial services industry, he worked as a corporate lawyer for seven years in Brazil and New York at the law firms of Machado Meyer, Pinheiro Guimaraes and Sidley Austin. He holds an LL.B. from the University of São Paulo, concluded in December 2000, and an LL.M. from Columbia University, concluded in May 2004, where he was awarded the James Kent Scholar award for graduating at the top 2% of his class.",
                    "Mateus Tessler | <i>Managing Partner</i>": "Mateus Tessler Rocha | Managing Partner",
                    "Sócio responsável pela área de <i>Corporate</i> NPL, antes de entrar na Jive Mateus trabalhou nas áreas de fusões e aquisições, consultoria em finanças corporativas na Deloitte, <i>Private Equity</i> e <i>Venture Capital</i> como gestor de recursos na Invest Tech e DLM Invista. Ao longo da carreira, participou ativamente de cerca de 30 processos de M&A e acompanhou 12 investimentos de Private Equity. Advogado formado pelo Mackenzie, com <i>pós-graduado em finanças</i> pelo INSPER.": "Before joining Jive in May 2013, Mateus worked in M&A and corporate finance advising at Deloitte (2006 – 2008), as a private equity and venture capital portfolio manager at Invest Tech (2008 - 2010) and at DLM Invista (2010 to 2013). During his career he participated in over 30 M&A transactions and managed approximately 12 private equity investments. He graduated in December 2005 with a Law degree from Mackenzie University and also studied a postgraduate course in finance from INSPER in June 2008.",
                    "Marcelo Martins | <i>Managing Partner</i>": "Marcelo Martins | Managing Partner",
                    "Sócio responsável pelo desenvolvimento de parcerias internacionais e co-investimentos. Antes de entrar na Jive, Marcelo trabalhou por 12 anos no segmento de <i>Private Equity</i> e Banco de Investimento, com foco na originação, execução e monitoramento de transações nos Estados Unidos, Ásia, Europa e no Brasil, tendo trabalhado na Axiom Capital Management, GP Investimentos e no banco Goldman Sachs. Ele também co-fundou a Spring Wireless, uma empresa de tecnologia que teve como investidores a Intel Capital, Goldman Sachs e o banco UBS. Tem MBA pela <i>Harvard Business School</i> e diploma de engenharia elétrica pela <i>Karlsruhe University</i> (Alemanha) e UNICAMP (Brasil).": "Founding Partner responsible for developing international partnerships and co-investments, Marcelo worked for 12 years in Private Equity and Investment Bank focusing on the origination, execution and monitoring of transactions in the United States, Asia, Europe and in Brazil. He also worked for Axiom Capital Management, GP Investimentos, Goldman Sachs, and co-founded Spring Wireless - a technology company that had as investors Intel Capital, Goldman Sachs and UBS. Marcelo has an MBA from Harvard Business School and an electrical engineering degree from UNICAMP (Brazil).",
                    "Diego Fonseca | <i>Managing Partner</i>": "Diego Fonseca | Managing Partner",
                    "Sócio responsável pelas áreas de <i>Real Estate, Investor Relations</i>, Finanças e Operações, Diego ingressou na Jive após 11 anos de atuação no Credit Suisse Hedging-Griffo (CSHG). Como <i>Managing Director</i> da CSHG foi COO e <i>Head of Products</i> para o <i>Private Banking</i>. Antes disso, atuou no <i>Corporate & Investment Bank</i> do ABN AMRO Bank no Brasil por cinco anos, após ter trabalhado na Rio Bravo Investimentos como analista de finanças estruturadas. É bacharel em Administração de Empresas pela EAESP-FGV e em Direito pela Universidade de São Paulo (USP). Possui MBA em <i>Banking</i> pela Fundação Dom Cabral e pós-graduação em Direito Tributário pela EDESP-FGV.": "Diego joined Jive after 11 years working at Credit Suisse Hedging-Griffo (CSHG). As Managing Director, his last responsibility at CSHG was of COO and Head of Products - Private Banking. Prior to CSHG, he worked in the Corporate & Investment Bank division of ABN AMRO Bank in Brazil for 5 years, as RM of the Global Financial Institutions Group, actively participating in several Structured Loans, M&A, portfolio assignments and capital market deals. Previously, he worked at Rio Bravo Investimentos as a structured finance analyst. He holds a Bachelor’s Degree in Business Administration from FGV-EAESP and a Bachelor of Law from the University of São Paulo (USP). He also holds an MBA in Banking from Fundação Dom Cabral and a post-graduate degree in Tax Law from FGV-EDESP.",
                    "André Balista | <i>Managing Partner</i>": "André Balista | Managing Partner",
                    "Sócio responsável por investimentos em ativos públicos, ingressou na Jive em 2011 e estruturou a área de <i>Real Estate</i> em 2012. Antes da Jive, André fundou a Effectua Capital e liderou projetos de M&A e captação de recursos. Anteriormente, conduziu projetos de reestruturação financeira, planejamento estratégico, <i>due diligence</i> e controladoria na NeoIntelligence, além de ter exercido posições nas áreas de produto na Coca-Cola e em uma companhia de alimentos chamada Mãe Terra.  É Bacharel em Administração de Empresas <i>pela EAESP-FGV, com MBA</i> Executivo em Finanças pelo INSPER.": "André joined Jive in 2011 and is an expert on middle-market M&A. He is a co-founder of Effectua Capital (2010), where he led and managed fundraising projects. Prior to this experience, he managed projects relating to financial restructuring, business planning, auditing, due diligence and controlling at NEO. He was also an entrepreneur and held product management positions at Coca-Cola Andina (2007-2008) and was a Private Equity investee at Mãe Terra (2008-2010).",

                    // JIVE DISTRESSED FIM CP

                    "OBJETIVO": "OBJECTIVE",
                    "PÚBLICO-ALVO": "TARGET AUDIENCE",
                    "POLÍTICA DE INVESTIMENTO": "INVESTMENT POLICY",
                    "A política de investimento consiste em detectar as distorções de preços em diferentes ativos nos vários mercados, bem como antecipar movimentos que estejam se formando, com o objetivo de superar constantemente a variação do CDI, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.": "The Investment Policy consists in detecting price distortions in different assets in the market as well as anticipating possible market movements. With the goal of constantly overcoming the CDI variation and adjusting to the risks of the operation, resources are properly reallocated according to the terms established in Article 5.2 of the Fund´s Bylaws.",
                    "O Fundo destina-se apenas a investidores qualificados e está atualmente fechado.": "The fund is exclusively targeted to qualified investors and it is currently closed.",
                    "Os recursos serão alocados exclusivamente nos seguintes ativos de veículos": "All resources will be exclusively allocated in the following asset vehicles:",
                    "Cotas do <i>corporate</i> NPL - fundo de investimento em direitos creditórios não padronizados.": "Corporate NPL Quotas: FIDC-NP (Credit Rights Investment Fund)",
                    "Cotas do Creditmix - fundo de investimento em direitos creditórios não padronizados.": "Creditmix Quotas: FIDC-NP (Credit Rights Investment Fund)",
                    "Cotas do fundo de renegociação de débitos - fundo de investimento em direitos creditórios não padronizados.": "Quotas of the Debt Renegotiation Fund: FIDC-NP (Credit Rights Investment Fund)",
                    "Cotas do fundo de recuperação de ativos – fundo de investimento em direitos creditórios não padronizados.": "Quotas of the Asset Recovery Fund: FIDC-NP (Credit Rights Investment Fund)",
                    "Cotas de outros (i) FIDCS NP, (ii) FIPS com propriedades como ativos alvo, (iii) FIIS (fundos imobiliários).": "Quotas from others (i) FIDCS NP, (ii) FIPs with properties as target assets (iii) FIIs (real estate funds)",

                    // JIVE DISTRESSED FIM II CP

                    "O fundo é destinado às  aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos neste regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.": "The fund is exclusively targeted to professional investors who are looking for the appreciation of their quotas and are ready to accept the risks described in the Fund´s Bylaws. The fund’s risks – and consequently the investors – are exposed to its investment policy in which the investors cannot claim back or attempt to rescue its quotas once they are applied in the Fund.",
                    "Cotas do fundo de liquidação financeira FIDC NP": "Quotas of the Fundo de Liquidação Financeira (FIDC-NP)",
                    "Cotas do Jive Distressed Allocation II FIC FIM CP": "Quotas of the Fund Jive Ativos Imobiliários II (FII 2)",
                    "Cotas do CSHG Jive D Allocation II FIC FIM CP IE": "Quotas of the Fund Investimento em Participações (FIP Distressed II)",
                    "Cotas do Jive Distressed II FIM CP INVEST EX": "Quotas of the Fund Jive Precatórios (FIDC-NP)",


                    // JIVE DISTRESSED FIM III CP

                    "A política de investimento consiste em detectar as distorções de preços em diferentes Ativos nos vários mercados, bem como antecipar movimentos que se estejam formando, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.": "The investment policy consists of detecting price distortions in different Assets in the various markets, as well as anticipating movements that are taking place, always adjusted to the risks inherent in the operations that performs, allocating its resources in accordance with its investment policy, pursuant to Article 5.2 of the Bylaws.",
                    "O fundo é destinado às aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos no regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.": "The fund is exclusively targeted to professional investors who are looking for the appreciation of their quotas and are ready to accept the risks described in the Fund´s Bylaws. The fund’s risks – and consequently the investors – are exposed to its investment policy in which the investors cannot claim back or attempt to rescue its quotas once they are subscribed to the Fund.",
                    "jiveDistressedFimIIICpInvestmentPolicyIII": "All resources will be exclusively allocated in the following financial assets, subject to the limits provided for in the Bylaws, as applicable:",
                    "(i) cotas de Fundos Alvo, que terão como ativos-alvo, direta ou indiretamente, Ativos Distressed, Ativos de Crédito, Ativos Oportunísticos e/ou Ativos Novas Teses, observada a regulamentação aplicável;": "Quotas of Target Funds, which will have as target assets, directly or indirectly, Distressed Assets, Credit Assets, Opportunistic Assets and/or New Thesis Assets, subject to the applicable Bylaws;",
                    "(ii) cotas de Fundos Co-investimento;": "Quotas of Co-investment Funds;",
                    "(iii) até 20% (vinte por cento) da totalidade do capital subscrito pelos Cotistas, para integralização de Cotas, em ativos negociados ou emitidos no exterior, observado, em qualquer caso e a qualquer tempo, que tal percentual não poderá exceder o limite de 40% (quarenta por cento) de seu patrimônio líquido, nos termos da regulamentação aplicável;": "Up to 20% (twenty percent) of the total capital subscribed by the Quotaholders, for the payment in full of the quotas, in assets traded or issued abroad, observing, in any case and at any time, that such percentage it cannot exceed the limit of 40% (forty percent) of its net worth, the resources available in the Fund's cash may be invested exclusively in Other Assets.",
                    "(iv) os recursos disponíveis no caixa do Fundo poderão ser aplicados, exclusivamente, em Outros Ativos.": "",

                    // STRATEGIES

                    "ESTRATÉGIAS": "STRATEGIES",
                    "390M+": "BRL 390M+",
                    "20M+": "BRL 20M+",
                    "100M+": "BRL 100M+",
                    "em saldo devedor agregado a Corporate NPL": "in aggregate UPB for Corporate NPL",
                    "em valor de face para Precatórios": "in face value for Precatórios",
                    "em valor agregado para imóveis": "in aggregate value for Real Estate",
                    "em valor de face para outros ativos": "in face value for other assets",
                    "DIREITOS CREDITÓRIOS E PRECATÓRIOS": "CREDIT RIGHTS AND PRECATÓRIOS",
                    "Investimentos em direitos discutidos de ações judiciais, procedimentos administrativos e arbitragens.  Nosso foco são direitos contra a administração pública, incluindo precatórios e pré-precatórios, e contra grandes empresas públicas e privadas.": "Investments in credits discussed within the judicial process, administrative procedures and arbitration. Our focus are rights against public administration including precatórios and pré-precatórios, and against large public and private companies.",
                    "CRÉDITO ESTRUTURADO E <i>SPECIAL SITS</i>": "STRUCTURED CREDITS AND SPECIAL SITS",
                    "Investimentos em operações de crédito estruturado com alto retorno potencial, como financiamento de empresas em recuperação judicial (<i>DIP Financing</i>), financiamento lastreado em imóveis e outros ativos, conversão de dívida em participação, desalavancagem e reestruturação financeira e operacional.": "Investments in structured credit operations with high-potential return such as DIP financing, real estate backed financing and other assets including conversion of debt into equity, deleveraging and financial & operational restructuring.",
                    "IMÓVEIS E CRÉDITOS IMOBILIÁRIOS <i>DISTRESSED</i>": "DISTRESSED REAL ESTATE AND MORTGAGE PORTFOLIOS",
                    "Investimentos em ativos imobiliários urbanos ou rurais cujos proprietários possuam problemas de crédito ou liquidez ou estejam em processo de recuperação judicial, falência ou outros similares.  Também adquirimos imóveis com pendências jurídicas ou de posse, além de estoques de imóveis de construtoras ou entidades governamentais, ativos não operacionais de empresas e bens não de uso próprio (BNDU) de instituições financeiras.": "Active urban or rural real estate investments in which the property owner has credit or liquidity problems or is in a process of judicial reorganization, bankruptcy etc. We also acquire properties with judicial liens or possession issues, real estate inventory of developers or government entities, corporate non-operational assets and real estate owned portfolios (REO) from financial institutions.",
                    "PORTFÓLIO DE CRÉDITOS INADIMPLIDOS": "PORTFOLIO OF NON-PERFORMING LOANS",
                    "Investimentos em carteiras de créditos contra empresas de médio porte vencidos e não pagos (“NPLs”), com ou sem garantias, incluindo créditos contra empresas em situação de falência ou recuperação judicial.": "Investments in portfolios of non-performing loans against medium-sized and large companies, with or without collateral, including credits against companies going through bankruptcy or judicial reorganization.",
                    "CORPORATE SINGLE-NAMES": "CORPORATE SINGLE-NAMES",
                    "LITIGATION FINANCE": "LITIGATION FINANCE",
                    "Investimentos em financiamento de litígios (third-party funding). Nosso foco é financiar disputas arbitrais e judiciais, nacionais e internacionais, envolvendo tanto players do setor privado quanto entes públicos, com grandes chances de êxito do financiado. A partir do trabalho de uma equipe experiente em litígios complexos, maximizamos o valor do litígio para todos os stakeholders.": "Investments in litigation funding (third-party funding). Our focus is to finance arbitral and judicial disputes, pending or to be filed in national and international territories, involving public and private companies, with good chances of success to the financed party. From the work of a team with large experience in complex litigation, we can maximize the amount involved in the dispute for all the stakeholders.",
                    "Investimentos em créditos contra grandes empresas vencidos e não pagos de alta complexidade, com ou sem garantias, contra empresas públicas ou privadas.": "Investments in non-performing credits against large troubled companies, with or without collateral, both government or privately owned.",
                    "VENDA SEU ATIVO": "VENDA SEU ATIVO",
                    "CONHEÇA AS SOLUÇÕES QUE A JIVE POSSUI PARA VOCÊ MONETIZAR O SEU CRÉDITO, ATIVO JUDICIAL OU IMÓVEL EM SITUAÇÃO COMPLEXA": "CONHEÇA AS SOLUÇÕES QUE A JIVE POSSUI PARA VOCÊ MONETIZAR O SEU CRÉDITO, ATIVO JUDICIAL OU IMÓVEL EM SITUAÇÃO COMPLEXA",

                    // SERVICING

                    "A plataforma de <i>servicing</i> da Jive conta com mais de 10 anos de atuação na gestão e recuperação de créditos atrasados de alta complexidade e ativos <i>distressed</i> em geral. A Jive conta com uma equipe multidisciplinar totalmente dedicada à gestão, negociação e cobrança de créditos corporativos, além de áreas especializadas em ativos imobiliários e na investigação e localização de ativos, no Brasil e no exterior. Com uma rede de escritórios de advocacia parceiros espalhados pelo Brasil e <i>offshore</i>, a Jive utiliza tecnologias inovadoras incluindo <i>big data</i> e <i>analytics</i> para definição das melhores estratégias.": "The servicing platform at Jive counts with more than 10 years of experience in the managing and recovering of high-complexity assets and distressed assets in general. Jive has a multidisciplinary team totally dedicated to the management, negotiation and collection of corporate credits, in addition to the specialized areas in real estate properties and asset tracing in Brazil and abroad. With a solid network of law offices and strategic suppliers all over Brazil and abroad, Jive utilizes innovative technologies including big data and analytics to define the best strategies and solutions for distressed assets.",
                    "Focamos na prestação de serviço para empresas de grande porte, com carteiras de créditos inadimplidos com valor total acima de R$ 100 milhões e R$ 1 milhão por crédito.": "We focus on servicing portfolios of corporate non-performing loans with an aggregate face value above R$ 100 million and R$ 1 million per credit.",
                    "processos judiciais": "judicial processes",
                    "créditos": "credits",
                    "imóveis": "real estate properties",
                    "escritórios parceiros": "law firms (nationwide network)",
                    "PLATAFORMA DE SERVIÇOS ROBUSTA E ESCALÁVEL": "ROBUST, SCALED AND SOPHISTICATED SERVICING PLATFORM",
                    "Equipe de 180 profissionais experientes trabalhando em conjunto;": "In-house servicing team with over 200 professionals",
                    "Conexão com escritórios de advocacia em todo o país;": "Network of more than 60 law firms nationwide",
                    "Gerenciamento de processos e propriedades imobiliárias.": "Management of judicial processes and distressed real estate properties",
                    "EQUIPE DE GESTÃO ESPECIALIZADA": "SPECIALIZED MANAGEMENT TEAM",
                    "Equipe multidisciplinar com experiência em diferentes classes de ativos distressed;": "Multidisciplinary team of professionals with experience across different distressed asset classes",
                    "Habilidade comprovada em buscar e executar acordos;": "Proven ability to source and execute deals",
                    "Investigação de ativos no Brasil e no exterior.": "Senior management team has been working together since 2010",
                    "FOCO CONTÍNUO EM INOVAÇÃO E TECNOLOGIA": "FOCUS IN INNOVATION AND TECHNOLOGY",
                    "Tecnologia de ponta para garantir e fornecer os melhores resultados;": "Use of sophisticated data technology to underwrite and service investments",
                    "Plataforma digital proprietária que centraliza todas as informações relevantes para o credor (dados de devedores, empréstimos, garantias, ações judiciais etc);": "Jive´s software platform (JiveSoft) that centralizes all relevant information on debtors (data on debtors, loans, assets, judicial process, etc.)",
                    "Robôs proprietários para mapear centenas de bancos de dados públicos e privados e cruzam as informações para otimizar o esforço de cobrança.": "Robots developed in-house to scour hundreds of both public and private databases",

                    // JIVE IN THE MEDIA

                    "FONTE": "SOURCE:",
                    "Leia mais": "Read more",
                    "Relações com a Mídia – TM Comunicações": "Media Relations - TM Comunicações",
                    "Notícia anterior": "Previous article",
                    "Próxima notícia": "Next article",

                    // SEND YOUR RESUMÉ

                    multiline: "SEND YOUR RESUME TO OUR <br/> RECRUITING TEAM",
                    "Nome *": "NAME *",
                    "E-mail *": "E-MAIL *",
                    "Telefone": "PHONE NUMBER",
                    "Cidade": "CITY",
                    "Estado": "STATE",
                    "CV *": "RESUME *",
                    "* Campos obrigatórios": "* Required Fields",
                    "* ATENÇÃO O envio do formulário poderá levar alguns segundos dependendo do tamanho do arquivo de currículo enviado. Pedimos que aguarde até a validação do envio.": "* DISCLAIMER: Sending this form may take a few seconds depending on the size of your file. We ask for you to be patient and wait until the process is completed.",
                    "ENVIAR": "SEND",
                    "Estou de acordo com as": "I agree with ",
                    "políticas de privacidade, termos de uso da Jive Investments": "Jive Investments privacy policies and terms of use",

                    // OPPORTUNITIES

                    "MANIFESTO JIVE": "JIVE MANIFESTO",
                    "Quer trabalhar conosco?": "WANT TO WORK WITH US?",
                    "Acompanhe nossas oportunidades no <1>LinkedIn</1>.": "FOLLOW US ON <1>LINKEDIN</1> TO BE UP TO DATE WITH OUR NEWS.",

                    // CONTACT

                    "Entre em contato": "CONTACT US",
                    "Telefone *": "PHONE NUMBER *",
                    "Empresa *": "COMPANY *",
                    "Assunto *": "SUBJECT *",
                    "Mensagem *": "MESSAGE *",

                    "Clicando em Aceito todos os cookies, você aceita nossa política de cookies com objetivo de melhorar sua navegação. Conheça nossa política e": "By clicking I accept all cookies, you accept our cookie policy in order to improve your browsing experience. Discover our policy and ",
                    "configure seus cookies": "set your cookies",
                    "Utilizamos cookies para garantir a melhor experiência. Alguns são essenciais para o funcionamento, como o login, e não podem ser desativados. Temos também cookies para melhorar a performance, oferecer conteúdo personalizado, recursos nas redes sociais e análise de tráfego. Estes cookies podem incluir cookies de terceiros.": "We use cookies to ensure the best experience. Some are essential for operation, such as login, and cannot be turned off. We also have cookies to improve performance, offer personalized content, social media features and traffic analysis. These cookies may include third-party cookies.",
                    "Cookies Funcionais": "Functional Cookies",
                    "Estes cookies são essenciais para o funcionamento do website. Sem estes, os nossos websites não funcionariam da melhor forma. Estes são armazenados temporariamente como informação de login e expiram assim que o navegador é fechado.": "These cookies are essential for the website to function. Without these, our websites would not function optimally. These are temporarily stored as login information and expire once the browser is closed.",
                    "Cookies Analíticos": "Analytical Cookies",
                    "A informação fornecida pelos cookies analíticos nos permite analisar padrões do comportamento do visitante, e essas informações são utilizadas para melhorar a experiência em geral ou identificar áreas do website que necessitam de manutenção. Esta informação é anônima (não pode ser usada para identificá-lo e não contêm informação pessoal como nome e endereço de e-mail) e é apenas utilizada para fins estatísticos. Cookies comportamentais são semelhantes aos analíticos e registram a sua visita ao website e usam a informação para fornecer conteúdo de acordo com os seus interesses.": "The information provided by analytical cookies allows us to analyze visitor behavior patterns, and this information is used to improve the overall experience or identify areas of the website that need maintenance. This information is anonymous (it cannot be used to identify you and does not contain personal information such as name and email address) and is only used for statistical purposes. Behavioral cookies are similar to analytics and record your visit to the website and use the information to deliver content tailored to your interests.",
                    "Aceitar todos os coockies": "Accept all cookies",
                    "salvar configurações": "save settings",
                    "NOSSA POLÍTICA DE COOKIES": "OUR COOKIES POLICY",
                    "MUDE SUA CONFIGURAÇÃO": "CHANGE YOUR SETTING",

                    // COMPLIANCE

                    "Compliance": "Compliance",
                    "EM ATENDIMENTO ÀS EXIGÊNCIAS REGULATÓRIAS, E COM O OBJETIVO DE MANTER TRANSPARÊNCIA EM NOSSAS AÇÕES, DISPONIBILIZAMOS ABAIXO OS SEGUINTES MANUAIS, CÓDIGOS E POLÍTICAS": "IN ORDER TO COMPLY WITH THE REGULATORY REQUIREMENTS AND MAINTAIN TRANSPARENCY IN OUR ACTIONS, WE PROVIDE BELOW THE FOLLOWING MANUALS, CODES AND POLICIES:",

                    // DISCLAIMER

                    "Este conteúdo foi preparado pela Jive Investments para fins meramente informativos e não deve ser entendido como análise de valor mobiliário, material promocional, solicitação de compra ou venda, oferta ou recomendação de qualquer ativo financeiro ou investimento. As referências aos produtos e serviços são meramente indicativas e não consideram os objetivos de investimento, a situação financeira ou necessidades individuais e particulares do investidor.": "This content was prepared by Jive Investments merely for informative ends and it should not be understood as an analysis of real estate value, promotional content, buy or sell requests, offers or recommendations of any financial asset or investment. These product references and services are merely indicators and are not considered objects of investment, financial situations or individual necessities of investors.",
                    "Recomendamos uma consulta ao seu assessor de investimento para análise específica e personalizada antes de sua decisão sobre quais os produtos e serviços adequados à sua situação e objetivos. Adicionalmente, recomendamos consulta a profissionais especializados e independentes antes de decidir sobre investimentos no exterior, investimentos imobiliários, tributação e sucessão. A Jive Investments não se responsabiliza por decisões do cliente acerca destes temas nem por ato ou fato dos profissionais por ele consultados. As informações veiculadas, valores e taxas são referentes às datas e condições indicadas no material e não serão atualizadas.": "We recommend a consultation with your financial advisor for a specific and personalized analysis for products and services suitable for your situation and personal goals. Additionally, we recommend a consultation with specialized professionals before any decisions on international investments, real estate investments, taxation and succession. Jive Investments does not hold accountability for any client decision around these topics or the act of any professionals consulted by them. The provided information, values and taxes are relative to the data and conditions indicated in this material and will not be updated.",
                    "A Jive Investments se reserva o direito de alterar qualquer condição, valor e taxas mencionados neste material a qualquer tempo e sem a necessidade de comunicação ou notificação. A Jive Investments poderá estabelecer, a seu exclusivo critério, requisitos e procedimentos específicos para abertura de contas e acesso a produtos. Os produtos e serviços mencionados estão sujeitos à disponibilidade e condições estabelecidas pela Jive Investments e serão prestados conforme autorizações legais concedidas às diferentes empresas integrantes do grupo.": "Jive Investments reserves the right to modify any condition, value or taxes mentioned in this material at any time and without any necessity of direct communication with clients. Jive will be able to establish, at its own discretion, requisites and specific procedures to the opening of accounts and access to specific products. The mentioned products and services are subjected to availability and conditions established by Jive Investments and will be rendered considering legal authorization conceded to the many companies that integrate the group.",
                    "O investimento em determinados ativos financeiros pode sujeitar o investidor a significativas perdas patrimoniais. Ao investidor cabe a responsabilidade de informar-se sobre todos os riscos previamente à tomada de decisão sobre investimentos. Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. É recomendada a leitura cuidadosa do prospecto e regulamento do fundo de investimento pelo investidor ao aplicar seus recursos.": "The investment of determined financial assets can subject the investor to significant financial and real estate loss. The investor must be held accountable and inform themselves about the high risks before making any investment decisions. Investment funds do not count with warranties from the Fund Administrator, Investment Manager insurance mechanisms’ or the FGC – Brazil’s Credit Fund. Past performance does not guarantee future results. Read the Fund´s PPM and Bylaws before investing. Reading and analyzing the Bylaws are highly recommended before the investor applies its resources.",
                    "<strong>Este material não pode ser copiado, reproduzido ou distribuído sem a expressa concordância da Jive Investments.</strong>": "<strong>This material cannot be copied, reproduced or distributed without the authorization of Jive Investments.</strong>",


                }
              },
            pt: {
                translations: {

                    // TOPO

                    "Sobre": "Sobre",
                    "A empresa": "A empresa",
                    "MANAGING PARTNERS": "MANAGING PARTNERS",
                    "Fundos": "Fundos",
                    "Estratégias": "Estratégias",
                    "Jive na mídia": "Jive na mídia",
                    "Eventos": "Eventos",
                    "Carreiras": "Carreiras",
                    "Currículos": "Currículos",
                    "CurriculosTitulo": "Currículos",
                    "Oportunidades": "Oportunidades",
                    "Contato": "Contato",

                    // HOME

                    "Maior gestora": "Maior gestora",
                    "INDEPENDENTE DE INVESTIMENTOS ALTERNATIVOS": "INDEPENDENTE DE INVESTIMENTOS ALTERNATIVOS",
                    "Somos uma gestora de investimentos alternativos com plataforma integrada de recuperação de créditos e gestão de imóveis e outros ativos <i>distressed</i>": "Somos uma gestora de investimentos alternativos com plataforma integrada de recuperação de créditos e gestão de imóveis e outros ativos <i>distressed</i>",
                    "SAIBA MAIS": "SAIBA MAIS",
                    "Notícias": "Notícias",
                    "Confira as últimas notícias que separamos sobre a Jive e o Mercado": "Confira as últimas notícias que separamos sobre a Jive e o Mercado",
                    "VER MAIS": "VER MAIS",
                    "MISSÃO": "MISSÃO",
                    "Criar valor transformando riscos em oportunidades de alto retorno para investidores, acionistas e sociedade": "Criar valor transformando riscos em oportunidades de alto retorno para investidores, acionistas e sociedade",
                    "VISÃO": "VISÃO",
                    vision: "Ser a mais admirada e respeitada casa <br/> de investimentos alternativos da América Latina",

                    // RODAPÉ

                    "ENDEREÇO": "ENDEREÇO",
                    addressJive: "Av. Brigadeiro Faria Lima, 1485 <br/> Torre Norte - 17º andar - CEP: 01452-002 <br/> São Paulo | SP - Brasil",
                    "TELEFONE": "TELEFONE",
                    "CANAL DE DENÚNCIAS": "CANAL DE DENÚNCIAS",
                    "Envie a sua denúncia aqui": "Envie a sua denúncia aqui",
                    "Conheça nossa página de <1><i>compliance</i></1>": "Conheça nossa página de <1><i>compliance</i></1>",
                    "SIGA-NOS": "SIGA-NOS",
                    "Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. Leia o prospecto e o regulamento antes de investir. © Jive Investments - Todos os direitos reservados - <1><i>Disclaimer</i></1>": "Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. Leia o prospecto e o regulamento antes de investir. © Jive Investments - Todos os direitos reservados - <1><i>Disclaimer</i></1>",
                    "Configurações de cookies": "Configurações de cookies",

                    // A EMPRESA

                    "A Empresa": "A Empresa",
                    "A Jive Investments é uma plataforma integrada de gestão de ativos alternativos com foco na originação, aquisição e recuperação de créditos inadimplidos (“NPL”), imóveis em situações complexas, ativos judiciais e outros ativos <i>distressed</i>.  Pioneira no investimento em ativos <i>distressed</i> no Brasil e maior <i>player</i> independente do mercado, a Jive possui um processo de recuperação de crédito totalmente integrado, incluindo a gestão de processos judiciais e administrativos de cobrança e o gerenciamento de imóveis e outros ativos físicos recuperados, dentro de uma plataforma única e escalável com utilização intensiva de tecnologia para a localização, precificação e recuperação de ativos.": "A Jive Investments é uma plataforma integrada de gestão de ativos alternativos com foco na originação, aquisição e recuperação de créditos inadimplidos (“NPL”), imóveis em situações complexas, ativos judiciais e outros ativos <i>distressed</i>.  Pioneira no investimento em ativos <i>distressed</i> no Brasil e maior <i>player</i> independente do mercado, a Jive possui um processo de recuperação de crédito totalmente integrado, incluindo a gestão de processos judiciais e administrativos de cobrança e o gerenciamento de imóveis e outros ativos físicos recuperados, dentro de uma plataforma única e escalável com utilização intensiva de tecnologia para a localização, precificação e recuperação de ativos.",
                    "A Jive investe continuamente em inovação e tecnologia de ponta para oferecer a melhor solução em recuperação de créditos, imóveis e outros ativos <i>distressed</i> para bancos, empresas e demais credores.": "A Jive investe continuamente em inovação e tecnologia de ponta para oferecer a melhor solução em recuperação de créditos, imóveis e outros ativos <i>distressed</i> para bancos, empresas e demais credores.",
                    "+de": "+de",
                    "investidores no Brasil e no exterior": "investidores no Brasil e no exterior",
                    "R$ 7,5 Bi": "R$ 7,5 Bi",
                    "em ativos sob gestão (AUM)": "em ativos sob gestão (AUM)",
                    "R$ 21 bi": "R$ 21 bi",
                    "em valor de face": "em valor de face",
                    "colaboradores": "colaboradores",

                    // HISTÓRIA

                    "HISTÓRIA": "HISTÓRIA",
                    "Em 2010, a Jive Investments foi criada para participar do leilão dos ativos do Lehman Brothers no Brasil. Investidores tanto locais quanto internacionais fizeram propostas para o portfólio do Lehman Brothers, mas a Jive apresentou uma proposta mais simples e adquiriu o portfólio com a aprovação da Corte de Falências de Nova Iorque em dezembro de 2010.": "Em 2010, a Jive Investments foi criada para participar do leilão dos ativos do Lehman Brothers no Brasil. Investidores tanto locais quanto internacionais fizeram propostas para o portfólio do Lehman Brothers, mas a Jive apresentou uma proposta mais simples e adquiriu o portfólio com a aprovação da Corte de Falências de Nova Iorque em dezembro de 2010.",
                    history2010: "Aquisição do portfólio do Lehman Brothers por 3% do valor de face de R$815 milhões",
                    history2011_2014: "15 investimentos diferentes em Corporate e Consumer NPL, assim como imóveis, utilizando capital proprietário e rendimentos do portfólio Lehman",
                    "Distribuição do Jive <i>Distressed</i> FIM CP IE (R$ 500 milhões)": "Captação do Fundo Jive I (R$ 500 MM)",
                    history2018: "Captação do Fundo Jive II (R$ 1,75 Bi)",
                    history2020: "Lançamento do Fundo Jive III com R$ 2,2 Bi captados onshore + R$ 1,3 Bi captados offshore + USD 80 MM via IFC",
                    history2021: "1,6x do capital comprometido distribuídos aos Investidores do Fundo I",

                    // EQUIPE DE GESTÃO

                    "Alexandre Cruz | <i>Managing Partner</i>": "Alexandre Cruz | <i>Managing Partner</i>",
                    "Sócio-fundador, é responsável pela estratégia, monitoramento da concorrência e tecnologia da Jive. Começou a investir em ativos estressados quando passou a adquirir estruturas locais e créditos não performados de subsidiárias de clientes internacionais da Neointelligence, uma empresa de contabilidade e <i>outsourcing</i> financeiro para clientes corporativos da qual foi sócio. Antes disso, Alexandre trabalhou na Ernst & Young e no banco Santander. É formado em economia pela Universidade de São Paulo (USP) e em direito pela PUC-SP, com MBA pela Fundação Getúlio Vargas em São Paulo.": "Sócio-fundador, é responsável pela estratégia, monitoramento da concorrência e tecnologia da Jive. Começou a investir em ativos estressados quando passou a adquirir estruturas locais e créditos não performados de subsidiárias de clientes internacionais da Neointelligence, uma empresa de contabilidade e <i>outsourcing</i> financeiro para clientes corporativos da qual foi sócio. Antes disso, Alexandre trabalhou na Ernst & Young e no banco Santander. É formado em economia pela Universidade de São Paulo (USP) e em direito pela PUC-SP, com MBA pela Fundação Getúlio Vargas em São Paulo.",
                    "Guilherme Ferreira | <i>Managing Partner</i>": "Guilherme Ferreira | <i>Managing Partner</i>",
                    "Sócio responsável pelas áreas de originação e estruturação de investimentos, Guilherme ingressou na Jive depois de 4 anos no Lehman Brothers, onde trabalhou inicialmente na mesa de renda fixa da América Latina em Nova Iorque e depois como liquidante da operação do Lehman Brothers na América Latina. Antes de ingressar no mercado financeiro, trabalhou como advogado com foco em direito empresarial por sete anos no Brasil e em Nova Iorque. É formado em direito pela Universidade de São Paulo (USP) e é mestre em direito (LLM) pela <i>Columbia University</i> em Nova Iorque, onde recebeu o <i>James Kent Awarda</i> por graduar-se entre os melhores de sua turma.": "Sócio responsável pelas áreas de originação e estruturação de investimentos, Guilherme ingressou na Jive depois de 4 anos no Lehman Brothers, onde trabalhou inicialmente na mesa de renda fixa da América Latina em Nova Iorque e depois como liquidante da operação do Lehman Brothers na América Latina. Antes de ingressar no mercado financeiro, trabalhou como advogado com foco em direito empresarial por sete anos no Brasil e em Nova Iorque. É formado em direito pela Universidade de São Paulo (USP) e é mestre em direito (LLM) pela <i>Columbia University</i> em Nova Iorque, onde recebeu o <i>James Kent Awarda</i> por graduar-se entre os melhores de sua turma.",
                    "Mateus Tessler | <i>Managing Partner</i>": "Mateus Tessler | <i>Managing Partner</i>",
                    "Sócio responsável pela área de <i>Corporate</i> NPL, antes de entrar na Jive Mateus trabalhou nas áreas de fusões e aquisições, consultoria em finanças corporativas na Deloitte, <i>Private Equity</i> e <i>Venture Capital</i> como gestor de recursos na Invest Tech e DLM Invista. Ao longo da carreira, participou ativamente de cerca de 30 processos de M&A e acompanhou 12 investimentos de Private Equity. Advogado formado pelo Mackenzie, com <i>pós-graduado em finanças</i> pelo INSPER.": "Sócio responsável pela área de <i>Corporate NPL</i> e Operações. Antes de entrar na Jive, Mateus trabalhou nas áreas de fusões, aquisições e consultoria em finanças corporativas na Deloitte, e Private Equity e Venture Capital como gestor de recursos na Invest Tech e DLM Invista. Ao longo da carreira, participou ativamente de cerca de 30 processos de M&A e acompanhou 12 investimentos de <i>Private Equity</i>. Advogado formado pelo Mackenzie, também completou um curso em finanças pelo INSPER.",
                    "Marcelo Martins | <i>Managing Partner</i>": "Marcelo Martins | <i>Managing Partner</i>",
                    "Sócio responsável pelo desenvolvimento de parcerias internacionais e co-investimentos. Antes de entrar na Jive, Marcelo trabalhou por 12 anos no segmento de <i>Private Equity</i> e Banco de Investimento, com foco na originação, execução e monitoramento de transações nos Estados Unidos, Ásia, Europa e no Brasil, tendo trabalhado na Axiom Capital Management, GP Investimentos e no banco Goldman Sachs. Ele também co-fundou a Spring Wireless, uma empresa de tecnologia que teve como investidores a Intel Capital, Goldman Sachs e o banco UBS. Tem MBA pela <i>Harvard Business School</i> e diploma de engenharia elétrica pela <i>Karlsruhe University</i> (Alemanha) e UNICAMP (Brasil).": "Sócio-fundador e responsável pelo desenvolvimento de parcerias internacionais e co-investimentos, Marcelo trabalhou por 12 anos no segmento de Private Equity e Banco de Investimento – focando na originação, execução e monitoramento de transações nos Estados Unidos, Ásia, Europa e no Brasil, tendo trabalhado na Axiom Capital Management, GP Investimentos e no banco Goldman Sachs. Ele também co-fundou a Spring Wireless, uma empresa de tecnologia que teve como investidores a Intel Capital, Goldman Sachs e o banco UBS. Marcelo tem MBA pela Harvard Business School e diploma de engenharia elétrica pela UNICAMP.",
                    "Diego Fonseca | <i>Managing Partner</i>": "Diego Fonseca | <i>Managing Partner</i>",
                    "Sócio responsável pelas áreas de <i>Real Estate, Investor Relations</i>, Finanças e Operações, Diego ingressou na Jive após 11 anos de atuação no Credit Suisse Hedging-Griffo (CSHG). Como <i>Managing Director</i> da CSHG foi COO e <i>Head of Products</i> para o <i>Private Banking</i>. Antes disso, atuou no <i>Corporate & Investment Bank</i> do ABN AMRO Bank no Brasil por cinco anos, após ter trabalhado na Rio Bravo Investimentos como analista de finanças estruturadas. É bacharel em Administração de Empresas pela EAESP-FGV e em Direito pela Universidade de São Paulo (USP). Possui MBA em <i>Banking</i> pela Fundação Dom Cabral e pós-graduação em Direito Tributário pela EDESP-FGV.": "Sócio responsável pelas áreas de <i>Real Estate</i>, <i>Investor Relations</i> e Finanças, Diego ingressou na Jive após 11 anos de atuação no Credit Suisse Hedging-Griffo (CSHG). Como <i>Managing Director</i> da CSHG foi COO e <i>Head of Products</i> para o Private Banking. Antes disso, atuou no Corporate & Investment Bank do ABN AMRO Bank no Brasil por cinco anos, após ter trabalhado na Rio Bravo Investimentos como analista de finanças estruturadas. É bacharel em Administração de Empresas pela EAESP-FGV e em Direito pela Universidade de São Paulo (USP). Possui MBA em Banking pela Fundação Dom Cabral e pós-graduação em Direito Tributário pela EDESP-FGV.",
                    "André Balista | <i>Managing Partner</i>": "André Balista | <i>Managing Partner</i>",
                    "Sócio responsável por investimentos em ativos públicos, ingressou na Jive em 2011 e estruturou a área de <i>Real Estate</i> em 2012. Antes da Jive, André fundou a Effectua Capital e liderou projetos de M&A e captação de recursos. Anteriormente, conduziu projetos de reestruturação financeira, planejamento estratégico, <i>due diligence</i> e controladoria na NeoIntelligence, além de ter exercido posições nas áreas de produto na Coca-Cola e em uma companhia de alimentos chamada Mãe Terra.  É Bacharel em Administração de Empresas <i>pela EAESP-FGV, com MBA</i> Executivo em Finanças pelo INSPER.": "Sócio responsável por investimentos em ativos públicos, ingressou na Jive em 2011 e estruturou a área de <i>Real Estate</i> em 2012. Antes da Jive, André fundou a Effectua Capital e liderou projetos de M&A e captação de recursos. Anteriormente, conduziu projetos de reestruturação financeira, planejamento estratégico, <i>due diligence</i> e controladoria na NeoIntelligence, além de ter exercido posições nas áreas de produto na Coca-Cola e em uma companhia de alimentos chamada Mãe Terra.  É Bacharel em Administração de Empresas <i>pela EAESP-FGV, com MBA</i> Executivo em Finanças pelo INSPER.",

                    // JIVE DISTRESSED FIM CP

                    "OBJETIVO": "OBJETIVO",
                    "PÚBLICO-ALVO": "PÚBLICO-ALVO",
                    "POLÍTICA DE INVESTIMENTO": "POLÍTICA DE INVESTIMENTO",
                    "A política de investimento consiste em detectar as distorções de preços em diferentes ativos nos vários mercados, bem como antecipar movimentos que estejam se formando, com o objetivo de superar constantemente a variação do CDI, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.": "A política de investimento consiste em detectar as distorções de preços em diferentes ativos nos vários mercados, bem como antecipar movimentos que estejam se formando, com o objetivo de superar constantemente a variação do CDI, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.",
                    "O Fundo destina-se apenas a investidores qualificados e está atualmente fechado.": "O Fundo destina-se apenas a investidores qualificados e está atualmente fechado.",
                    "Os recursos serão alocados exclusivamente nos seguintes ativos de veículos": "Os recursos serão alocados exclusivamente nos seguintes ativos de veículos",
                    "Cotas do <i>corporate</i> NPL - fundo de investimento em direitos creditórios não padronizados.": "Cotas do <i>corporate</i> NPL - fundo de investimento em direitos creditórios não padronizados.",
                    "Cotas do Creditmix - fundo de investimento em direitos creditórios não padronizados.": "Cotas do Creditmix - fundo de investimento em direitos creditórios não padronizados.",
                    "Cotas do fundo de renegociação de débitos - fundo de investimento em direitos creditórios não padronizados.": "Cotas do fundo de renegociação de débitos - fundo de investimento em direitos creditórios não padronizados.",
                    "Cotas do fundo de recuperação de ativos – fundo de investimento em direitos creditórios não padronizados.": "Cotas do fundo de recuperação de ativos – fundo de investimento em direitos creditórios não padronizados.",
                    "Cotas de outros (i) FIDCS NP, (ii) FIPS com propriedades como ativos alvo, (iii) FIIS (fundos imobiliários).": "Cotas de outros (i) FIDCS NP, (ii) FIPS com propriedades como ativos alvo, (iii) FIIS (fundos imobiliários).",
                    "Informativo mensal": "Informativo mensal",
                    "Faça o download do PDF do mês vigente": "Faça o download do PDF do mês vigente:",

                    // JIVE DISTRESSED FIM II CP

                    "O fundo é destinado às  aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos neste regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.": "O fundo é destinado às  aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos neste regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.",
                    "Cotas do fundo de liquidação financeira FIDC NP": "Cotas do fundo de liquidação financeira FIDC NP",
                    "Cotas do Jive Distressed Allocation II FIC FIM CP": "Cotas do Jive Distressed Allocation II FIC FIM CP",
                    "Cotas do CSHG Jive D Allocation II FIC FIM CP IE": "Cotas do CSHG Jive D Allocation II FIC FIM CP IE",
                    "Cotas do Jive Distressed II FIM CP INVEST EX": "Cotas do Jive Distressed II FIM CP INVEST EX",

                    // JIVE DISTRESSED FIM III CP

                    "A política de investimento consiste em detectar as distorções de preços em diferentes Ativos nos vários mercados, bem como antecipar movimentos que se estejam formando, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.": "A política de investimento consiste em detectar as distorções de preços em diferentes Ativos nos vários mercados, bem como antecipar movimentos que se estejam formando, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.",
                    "O fundo é destinado às aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos no regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.": "O fundo é destinado às aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos no regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.",
                    "jiveDistressedFimIIICpInvestmentPolicyIII": "Os recursos serão alocados exclusivamente nos seguintes ativos financeiros, observados os limites previstos pelos Artigos 5.2.3 e 5.2.5 do Regulamento, conforme aplicáveis:",
                    "(i) cotas de Fundos Alvo, que terão como ativos-alvo, direta ou indiretamente, Ativos Distressed, Ativos de Crédito, Ativos Oportunísticos e/ou Ativos Novas Teses, observada a regulamentação aplicável;": "(i) cotas de Fundos Alvo, que terão como ativos-alvo, direta ou indiretamente, Ativos Distressed, Ativos de Crédito, Ativos Oportunísticos e/ou Ativos Novas Teses, observada a regulamentação aplicável;",
                    "(ii) cotas de Fundos Co-investimento;": "(ii) cotas de Fundos Co-investimento;",
                    "(iii) até 20% (vinte por cento) da totalidade do capital subscrito pelos Cotistas, para integralização de Cotas, em ativos negociados ou emitidos no exterior, observado, em qualquer caso e a qualquer tempo, que tal percentual não poderá exceder o limite de 40% (quarenta por cento) de seu patrimônio líquido, nos termos da regulamentação aplicável;": "(iii) até 20% (vinte por cento) da totalidade do capital subscrito pelos Cotistas, para integralização de Cotas, em ativos negociados ou emitidos no exterior, observado, em qualquer caso e a qualquer tempo, que tal percentual não poderá exceder o limite de 40% (quarenta por cento) de seu patrimônio líquido, nos termos da regulamentação aplicável;",
                    "(iv) os recursos disponíveis no caixa do Fundo poderão ser aplicados, exclusivamente, em Outros Ativos.": "(iv) os recursos disponíveis no caixa do Fundo poderão ser aplicados, exclusivamente, em Outros Ativos.",


                    // ESTRATÉGIAS

                    "ESTRATÉGIAS": "ESTRATÉGIAS",
                    "200M+": "200M+",
                    "20M+": "20M+",
                    "em saldo devedor agregado a Corporate NPL": "em saldo devedor agregado a Corporate NPL",
                    "em valor de face para Precatórios": "em valor de face para Precatórios",
                    "em valor agregado para imóveis": "em valor agregado para imóveis",
                    "em valor de face para outros ativos": "em valor de face para outros ativos",
                    "DIREITOS CREDITÓRIOS E PRECATÓRIOS": "DIREITOS CREDITÓRIOS E PRECATÓRIOS",
                    "Investimentos em direitos discutidos de ações judiciais, procedimentos administrativos e arbitragens.  Nosso foco são direitos contra a administração pública, incluindo precatórios e pré-precatórios, e contra grandes empresas públicas e privadas.": "Investimentos em direitos discutidos de ações judiciais, procedimentos administrativos e arbitragens.  Nosso foco são direitos contra a administração pública, incluindo precatórios e pré-precatórios, e contra grandes empresas públicas e privadas.",
                    "CRÉDITO ESTRUTURADO E <i>SPECIAL SITS</i>": "CRÉDITO ESTRUTURADO E <i>SPECIAL SITS</i>",
                    "Investimentos em operações de crédito estruturado com alto retorno potencial, como financiamento de empresas em recuperação judicial (<i>DIP Financing</i>), financiamento lastreado em imóveis e outros ativos, conversão de dívida em participação, desalavancagem e reestruturação financeira e operacional.": "Investimentos em operações de crédito estruturado com alto retorno potencial, como financiamento de empresas em recuperação judicial (<i>DIP Financing</i>), financiamento lastreado em imóveis e outros ativos, conversão de dívida em participação, desalavancagem e reestruturação financeira e operacional.",
                    "IMÓVEIS E CRÉDITOS IMOBILIÁRIOS <i>DISTRESSED</i>": "IMÓVEIS E CRÉDITOS IMOBILIÁRIOS <i>DISTRESSED</i>",
                    "Investimentos em ativos imobiliários urbanos ou rurais cujos proprietários possuam problemas de crédito ou liquidez ou estejam em processo de recuperação judicial, falência ou outros similares.  Também adquirimos imóveis com pendências jurídicas ou de posse, além de estoques de imóveis de construtoras ou entidades governamentais, ativos não operacionais de empresas e bens não de uso próprio (BNDU) de instituições financeiras.": "Investimentos em ativos imobiliários urbanos ou rurais cujos proprietários possuam problemas de crédito ou liquidez ou estejam em processo de recuperação judicial, falência ou outros similares.  Também adquirimos imóveis com pendências jurídicas ou de posse, além de estoques de imóveis de construtoras ou entidades governamentais, ativos não operacionais de empresas e bens não de uso próprio (BNDU) de instituições financeiras.",
                    "PORTFÓLIO DE CRÉDITOS INADIMPLIDOS": "PORTFÓLIO DE CRÉDITOS INADIMPLIDOS",
                    "Investimentos em carteiras de créditos contra empresas de médio porte vencidos e não pagos (“NPLs”), com ou sem garantias, incluindo créditos contra empresas em situação de falência ou recuperação judicial.": "Investimentos em carteiras de créditos contra empresas de médio porte vencidos e não pagos (“NPLs”), com ou sem garantias, incluindo créditos contra empresas em situação de falência ou recuperação judicial.",
                    "<i>CORPORATE SINGLE-NAMES</i>": "<i>CORPORATE SINGLE-NAMES</i>",
                    "Investimentos em créditos contra grandes empresas vencidos e não pagos de alta complexidade, com ou sem garantias, contra empresas públicas ou privadas.": "Investimentos em créditos contra grandes empresas vencidos e não pagos de alta complexidade, com ou sem garantias, contra empresas públicas ou privadas.",
                    "VENDA SEU ATIVO": "VENDA SEU ATIVO",
                    "CONHEÇA AS SOLUÇÕES QUE A JIVE POSSUI PARA VOCÊ MONETIZAR O SEU CRÉDITO, ATIVO JUDICIAL OU IMÓVEL EM SITUAÇÃO COMPLEXA": "CONHEÇA AS SOLUÇÕES QUE A JIVE POSSUI PARA VOCÊ MONETIZAR O SEU CRÉDITO, ATIVO JUDICIAL OU IMÓVEL EM SITUAÇÃO COMPLEXA",

                    // MANUTENÇÃO

                    "A plataforma de <i>servicing</i> da Jive conta com mais de 10 anos de atuação na gestão e recuperação de créditos atrasados de alta complexidade e ativos <i>distressed</i> em geral. A Jive conta com uma equipe multidisciplinar totalmente dedicada à gestão, negociação e cobrança de créditos corporativos, além de áreas especializadas em ativos imobiliários e na investigação e localização de ativos, no Brasil e no exterior. Com uma rede de escritórios de advocacia parceiros espalhados pelo Brasil e <i>offshore</i>, a Jive utiliza tecnologias inovadoras incluindo <i>big data</i> e <i>analytics</i> para definição das melhores estratégias.": "A plataforma de <i>servicing</i> da Jive conta com mais de 10 anos de atuação na gestão e recuperação de créditos atrasados de alta complexidade e ativos <i>distressed</i> em geral. A Jive conta com uma equipe multidisciplinar totalmente dedicada à gestão, negociação e cobrança de créditos corporativos, além de áreas especializadas em ativos imobiliários e na investigação e localização de ativos, no Brasil e no exterior. Com uma rede de escritórios de advocacia parceiros espalhados pelo Brasil e <i>offshore</i>, a Jive utiliza tecnologias inovadoras incluindo <i>big data</i> e <i>analytics</i> para definição das melhores estratégias.",
                    "Focamos na prestação de serviço para empresas de grande porte, com carteiras de créditos inadimplidos com valor total acima de R$ 100 milhões e R$ 1 milhão por crédito.": "Focamos na prestação de serviço para empresas de grande porte, com carteiras de créditos inadimplidos com valor total acima de R$ 100 milhões e R$ 1 milhão por crédito.",
                    "processos judiciais": "processos judiciais",
                    "créditos": "créditos",
                    "imóveis": "imóveis",
                    "escritórios parceiros": "escritórios parceiros",
                    "PLATAFORMA DE SERVIÇOS ROBUSTA E ESCALÁVEL": "PLATAFORMA DE SERVIÇOS ROBUSTA E ESCALÁVEL",
                    "Equipe de 180 profissionais experientes trabalhando em conjunto;": "Equipe de 200 profissionais experientes trabalhando em conjunto;",
                    "Conexão com escritórios de advocacia em todo o país;": "Conexão com escritórios de advocacia em todo o país;",
                    "Gerenciamento de processos e propriedades imobiliárias.": "Gerenciamento de processos e propriedades imobiliárias.",
                    "EQUIPE DE GESTÃO ESPECIALIZADA": "EQUIPE DE GESTÃO ESPECIALIZADA",
                    "Equipe multidisciplinar com experiência em diferentes classes de ativos distressed;": "Equipe multidisciplinar com experiência em diferentes classes de ativos distressed;",
                    "Habilidade comprovada em buscar e executar acordos;": "Habilidade comprovada em buscar e executar acordos;",
                    "Investigação de ativos no Brasil e no exterior.": "Investigação de ativos no Brasil e no exterior.",
                    "FOCO CONTÍNUO EM INOVAÇÃO E TECNOLOGIA": "FOCO CONTÍNUO EM INOVAÇÃO E TECNOLOGIA",
                    "Tecnologia de ponta para garantir e fornecer os melhores resultados;": "Tecnologia de ponta para garantir e fornecer os melhores resultados;",
                    "Monitoramento em tempo real de centenas de fontes de informações para localizar o devedor e acompanhar sua movimentação patrimonial;": "Monitoramento em tempo real de centenas de fontes de informações para localizar o devedor e acompanhar sua movimentação patrimonial;",
                    "Plataforma digital proprietária que centraliza todas as informações relevantes para o credor (dados de devedores, empréstimos, garantias, ações judiciais etc);": "Plataforma digital proprietária que centraliza todas as informações relevantes para o credor (dados de devedores, empréstimos, garantias, ações judiciais etc);",
                    "Robôs proprietários para mapear centenas de bancos de dados públicos e privados e cruzam as informações para otimizar o esforço de cobrança.": "Robôs proprietários para mapear centenas de bancos de dados públicos e privados e cruzam as informações para otimizar o esforço de cobrança.",

                    // JIVE IN THE MEDIA

                    "FONTE": "FONTE:",
                    "Leia mais": "Leia mais",
                    "Relações com a Mídia – TM Comunicações": "Relações com a Mídia – TM Comunicações",
                    "Notícia anterior": "Notícia anterior",
                    "Próxima notícia": "Próxima notícia",

                    // Obs: Todo conteúdo é dinâmico, ou seja, se eles quiserem, a tradução deverá ser feita por eles,
                    // no momento em que incluírem um conteúdo. A regra vai ser exibir todos os eventos, seja em português e em inglês.
                    // Se tiver um evento com as 2 versões, exibe a respectiva.

                    // EVENTS

                    // Obs: Todo conteúdo é dinâmico, ou seja, se eles quiserem, a tradução deverá ser feita por eles,
                    // no momento em que incluírem um conteúdo. A regra vai ser exibir todos os eventos, seja em português e em inglês.
                    // Se tiver um evento com as 2 versões, exibe a respectiva.

                    // CURRÍCULOS

                    multiline: "Envie seu currículo para nossa equipe <br/> de Recrutamento e Seleção",
                    "Nome *": "Nome *",
                    "E-mail *": "E-mail *",
                    "Telefone": "Telefone",
                    "Cidade": "Cidade",
                    "Estado": "Estado",
                    "CV *": "CV *",
                    "* Campos obrigatórios": "* Campos obrigatórios",
                    "* ATENÇÃO O envio do formulário poderá levar alguns segundos dependendo do tamanho do arquivo de currículo enviado. Pedimos que aguarde até a validação do envio.": "* ATENÇÃO: O envio do formulário poderá levar alguns segundos dependendo do tamanho do arquivo de currículo enviado. Pedimos que aguarde até a validação do envio.",
                    "ENVIAR": "ENVIAR",

                    // OPORTUNIDADES

                    "MANIFESTO JIVE": "MANIFESTO JIVE",
                    "Quer trabalhar conosco?": "Quer trabalhar conosco?",
                    "Acompanhe nossas oportunidades no <1>LinkedIn</1>.": "Acompanhe nossas oportunidades no <1>LinkedIn</1>.",

                    // Obs: Todo conteúdo é dinâmico, ou seja, se eles quiserem, a tradução deverá ser feita por eles,
                    // no momento em que incluírem um conteúdo. A regra vai ser exibir todos os eventos, seja em português e em inglês.
                    // Se tiver um evento com as 2 versões, exibe a respectiva.

                    // CONTATO

                    "Entre em contato": "Entre em contato",
                    "Telefone *": "Telefone *",
                    "Empresa *": "Empresa *",
                    "Assunto *": "Assunto *",
                    "Mensagem *": "Mensagem *",
                    "Estou de acordo com as": "Estou de acordo com as ",
                    "políticas de privacidade, termos de uso da Jive Investments": "políticas de privacidade, termos de uso da Jive Investments",

                    // COMPLIANCE

                    "Compliance": "Compliance",
                    "EM ATENDIMENTO ÀS EXIGÊNCIAS REGULATÓRIAS, E COM O OBJETIVO DE MANTER TRANSPARÊNCIA EM NOSSAS AÇÕES, DISPONIBILIZAMOS ABAIXO OS SEGUINTES MANUAIS, CÓDIGOS E POLÍTICAS": "EM ATENDIMENTO ÀS EXIGÊNCIAS REGULATÓRIAS, E COM O OBJETIVO DE MANTER TRANSPARÊNCIA EM NOSSAS AÇÕES, DISPONIBILIZAMOS ABAIXO OS SEGUINTES MANUAIS, CÓDIGOS E POLÍTICAS:",

                    // Obs: Todo conteúdo é dinâmico, ou seja, se eles quiserem, a tradução deverá ser feita por eles,
                    // no momento em que incluírem um conteúdo. A regra vai ser exibir todos os eventos, seja em português e em inglês.
                    // Se tiver um evento com as 2 versões, exibe a respectiva.

                    // AVISO LEGAL
                    "Clicando em Aceito todos os cookies, você aceita nossa política de cookies com objetivo de melhorar sua navegação. Conheça nossa política e ": "Clicando em Aceito todos os cookies, você aceita nossa política de cookies com objetivo de melhorar sua navegação. Conheça nossa política e",
                    "configure seus cookies": "configure seus cookies",
                    "Utilizamos cookies para garantir a melhor experiência. Alguns são essenciais para o funcionamento, como o login, e não podem ser desativados. Temos também cookies para melhorar a performance, oferecer conteúdo personalizado, recursos nas redes sociais e análise de tráfego. Estes cookies podem incluir cookies de terceiros.": "Utilizamos cookies para garantir a melhor experiência. Alguns são essenciais para o funcionamento, como o login, e não podem ser desativados. Temos também cookies para melhorar a performance, oferecer conteúdo personalizado, recursos nas redes sociais e análise de tráfego. Estes cookies podem incluir cookies de terceiros.",
                    "Cookies Funcionais": "Cookies Funcionais",
                    "Estes cookies são essenciais para o funcionamento do website. Sem estes, os nossos websites não funcionariam da melhor forma. Estes são armazenados temporariamente como informação de login e expiram assim que o navegador é fechado.": "Estes cookies são essenciais para o funcionamento do website. Sem estes, os nossos websites não funcionariam da melhor forma. Estes são armazenados temporariamente como informação de login e expiram assim que o navegador é fechado.",
                    "Cookies Analíticos": "Cookies Analíticos",
                    "A informação fornecida pelos cookies analíticos nos permite analisar padrões do comportamento do visitante, e essas informações são utilizadas para melhorar a experiência em geral ou identificar áreas do website que necessitam de manutenção. Esta informação é anônima (não pode ser usada para identificá-lo e não contêm informação pessoal como nome e endereço de e-mail) e é apenas utilizada para fins estatísticos. Cookies comportamentais são semelhantes aos analíticos e registram a sua visita ao website e usam a informação para fornecer conteúdo de acordo com os seus interesses.": "A informação fornecida pelos cookies analíticos nos permite analisar padrões do comportamento do visitante, e essas informações são utilizadas para melhorar a experiência em geral ou identificar áreas do website que necessitam de manutenção. Esta informação é anônima (não pode ser usada para identificá-lo e não contêm informação pessoal como nome e endereço de e-mail) e é apenas utilizada para fins estatísticos. Cookies comportamentais são semelhantes aos analíticos e registram a sua visita ao website e usam a informação para fornecer conteúdo de acordo com os seus interesses.",
                    "Aceitar todos os coockies": "Aceitar todos os coockies",
                    "salvar configurações": "salvar configurações",
                    "NOSSA POLÍTICA DE COOKIES": "NOSSA POLÍTICA DE COOKIES",
                    "MUDE SUA CONFIGURAÇÃO": "MUDE SUA CONFIGURAÇÃO",

                    "Este conteúdo foi preparado pela Jive Investments para fins meramente informativos e não deve ser entendido como análise de valor mobiliário, material promocional, solicitação de compra ou venda, oferta ou recomendação de qualquer ativo financeiro ou investimento. As referências aos produtos e serviços são meramente indicativas e não consideram os objetivos de investimento, a situação financeira ou necessidades individuais e particulares do investidor.": "Este conteúdo foi preparado pela Jive Investments para fins meramente informativos e não deve ser entendido como análise de valor mobiliário, material promocional, solicitação de compra ou venda, oferta ou recomendação de qualquer ativo financeiro ou investimento. As referências aos produtos e serviços são meramente indicativas e não consideram os objetivos de investimento, a situação financeira ou necessidades individuais e particulares do investidor.",
                    "Recomendamos uma consulta ao seu assessor de investimento para análise específica e personalizada antes de sua decisão sobre quais os produtos e serviços adequados à sua situação e objetivos. Adicionalmente, recomendamos consulta a profissionais especializados e independentes antes de decidir sobre investimentos no exterior, investimentos imobiliários, tributação e sucessão. A Jive Investments não se responsabiliza por decisões do cliente acerca destes temas nem por ato ou fato dos profissionais por ele consultados. As informações veiculadas, valores e taxas são referentes às datas e condições indicadas no material e não serão atualizadas.": "Recomendamos uma consulta ao seu assessor de investimento para análise específica e personalizada antes de sua decisão sobre quais os produtos e serviços adequados à sua situação e objetivos. Adicionalmente, recomendamos consulta a profissionais especializados e independentes antes de decidir sobre investimentos no exterior, investimentos imobiliários, tributação e sucessão. A Jive Investments não se responsabiliza por decisões do cliente acerca destes temas nem por ato ou fato dos profissionais por ele consultados. As informações veiculadas, valores e taxas são referentes às datas e condições indicadas no material e não serão atualizadas.",
                    "A Jive Investments se reserva o direito de alterar qualquer condição, valor e taxas mencionados neste material a qualquer tempo e sem a necessidade de comunicação ou notificação. A Jive Investments poderá estabelecer, a seu exclusivo critério, requisitos e procedimentos específicos para abertura de contas e acesso a produtos. Os produtos e serviços mencionados estão sujeitos à disponibilidade e condições estabelecidas pela Jive Investments e serão prestados conforme autorizações legais concedidas às diferentes empresas integrantes do grupo.": "A Jive Investments se reserva o direito de alterar qualquer condição, valor e taxas mencionados neste material a qualquer tempo e sem a necessidade de comunicação ou notificação. A Jive Investments poderá estabelecer, a seu exclusivo critério, requisitos e procedimentos específicos para abertura de contas e acesso a produtos. Os produtos e serviços mencionados estão sujeitos à disponibilidade e condições estabelecidas pela Jive Investments e serão prestados conforme autorizações legais concedidas às diferentes empresas integrantes do grupo.",
                    "O investimento em determinados ativos financeiros pode sujeitar o investidor a significativas perdas patrimoniais. Ao investidor cabe a responsabilidade de informar-se sobre todos os riscos previamente à tomada de decisão sobre investimentos. Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. É recomendada a leitura cuidadosa do prospecto e regulamento do fundo de investimento pelo investidor ao aplicar seus recursos.": "O investimento em determinados ativos financeiros pode sujeitar o investidor a significativas perdas patrimoniais. Ao investidor cabe a responsabilidade de informar-se sobre todos os riscos previamente à tomada de decisão sobre investimentos. Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. É recomendada a leitura cuidadosa do prospecto e regulamento do fundo de investimento pelo investidor ao aplicar seus recursos.",
                    "<strong>Este material não pode ser copiado, reproduzido ou distribuído sem a expressa concordância da Jive Investments.</strong>": "<strong>Este material não pode ser copiado, reproduzido ou distribuído sem a expressa concordância da Jive Investments.</strong>",


              }
            }
        },
        fallbackLng: 'pt',
        debug: true,


        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
