/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './NoticiasDaJive.css';
import Layout from '../template/Layout';
import api from "../services/api";
import Pagination from "react-js-pagination";
import fnsBr from 'date-fns/locale/pt-BR'
import { trackPromise} from 'react-promise-tracker'
import {formatDate, getElementByLang, getLng} from "../helpers";
import {useTranslation} from "react-i18next";
import {Trans} from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function NoticiasDaJive(props) {
    const [reports, setReports] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [hashtag, setHashtag] = useState('');
    const [customClass] = useState('custom-header');
    const {  i18n } = useTranslation();

    const activeLng = getLng(i18n)

    useEffect(() => {
        document.body.classList.add(customClass);
        return () => {
            document.body.classList.remove(customClass);
        }
    },[customClass]);

    const changePage = (page) => {
        const params = new URLSearchParams(props.location.search);
        const hashtagQ = params.get('hashtag');
        setHashtag(hashtagQ ? `&hashtag=${hashtagQ}` : '');
        trackPromise(
          new Promise((resolve) => {
              api.get('/p/reports', {
                  params: {
                      page,
                      hashtag: hashtagQ
                  }
              })
                .then(({ data }) => {
                    const { docs, ...pageInfos } = data;
                    setPagination(pageInfos);
                    const aux = docs.map(item => {
                        const element = getElementByLang(item, activeLng, 'title')
                        return {
                            id: item.id,
                            ...element,
                            postedAt: item.postedAt,
                            hashtags: item.hashtags,
                            attachment: item.attachmentLarge
                        }
                    });
                    setReports(aux);
                    window.scrollTo(0, 0)
                    resolve();
                })
          })
        )
    }

    useEffect(() => {
        changePage(1);
    }, [props.location.search])

    const handlePageChange = (number) => {
        changePage(number);
    }

    const summary = (truncated, maxLength) => {
        if (truncated.length > maxLength) {
            truncated = truncated.substr(0,maxLength) + '...';
        }
        return truncated;
    }

    const dateToDay = (isoDate) => {
        return formatDate(new Date(isoDate), 'dd')
    }

    const dateToMonth = (isoDate) => {
        return formatDate(new Date(isoDate), 'MMM', { locale: fnsBr })
    }

    const dateToYear = (isoDate) => {
        return formatDate(new Date(isoDate), 'yyyy')
    }

    const renderReports = () => {
        return reports.map((i) => (
          <div className="col-md-12" key={i.id}>
              <div className="blog-classic">
                  <div className="date">
                      {dateToDay(i.postedAt)}
                      <span>{dateToMonth(i.postedAt)} {dateToYear(i.postedAt)}</span>
                  </div>
                  <div className="blog-post">
                      <div className="full-width">
                          <div className="post-slider post-img text-center relative">
                              <a href={`/noticia-interna?q=${i.id}${hashtag}`}>
                                  <img src={i.attachment} alt=""/>
                              </a>
                          </div>
                      </div>
                      <h4 className="text-uppercase"><a href={`/noticia-interna?q=${i.id}${hashtag}`}>{i.title}</a></h4>
                      <ul className="post-meta">
                          <li><i className="fa fa-user"></i><Trans>FONTE</Trans>{i.source}
                          </li>
                          <li>
                              {
                                  i.hashtags.map(i => (
                                    <span key={i.id}>
                                        <i className="fa fa-folder-open"></i>  <a href={`/jive-na-midia?hashtag=${i.id}`}>{i.portuguese.description}</a>
                                    </span>
                                  ))
                              }
                          </li>
                      </ul>
                      <p style={{fontSize: 17}}>{
                          summary(i.text.replace(/(<([^>]+)>)/ig, ''), 400)
                      }</p>
                      <a href={`/noticia-interna?q=${i.id}${hashtag}`} className="btn btn-small btn-dark-solid  "><Trans>Leia mais</Trans></a>
                  </div>
              </div>
          </div>
        ))
    }

  return (
    <Layout>
        <Helmet>
          <title>Mídia</title>
        </Helmet>
        <section className="page-title title-margin">
            <div className="container">
                <h4 className="text-uppercase"><Trans>Jive na mídia</Trans></h4>
                <Trans>
                    Relações com a Mídia – TM Comunicações
                </Trans>
                <ul className="post-meta">
                    <li><i className="fa fa-user"></i>Luciana Alves
                    </li>
                    <li><i className="fa fa-envelope"></i><a href="mailto:luciana@tmcomunicacoes.com.br">luciana@tmcomunicacoes.com.br</a>
                    </li>
                    <li><i className="fa fa-phone"></i><a href="tel:55-21-981313106">+55 (21) 98131-3106</a>
                    </li>
                </ul>
                <ul className="post-meta">
                    <li><i className="fa fa-user"></i>Fernanda Malcher
                    </li>
                    <li><i className="fa fa-envelope"></i><a href="mailto:fernanda@tmcomunicacoes.com.br">fernanda@tmcomunicacoes.com.br</a>
                    </li>
                    <li><i className="fa fa-phone"></i><a href="tel:55-21-991189393">+55 (21) 99118-9393</a>
                    </li>
                </ul>
            </div>
        </section>
        <section className="body-content" id="page-jive-na-midia">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        {
                            renderReports()
                        }
                        <div id='news-pagination' className="text-center">
                            { pagination && (
                              <Pagination
                                activePage={pagination.page}
                                itemsCountPerPage={pagination.limit}
                                totalItemsCount={pagination.totalDocs}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                              />
                            )}
                            {/*<ul className="pagination custom-pagination">*/}
                            {/*    <li><a href="/"><i className="fa fa-angle-left" aria-hidden="true"></i> </a>*/}
                            {/*    </li>*/}
                            {/*    <li className="active"><a href="/">1</a>*/}
                            {/*    </li>*/}
                            {/*    <li><a href="/">2</a>*/}
                            {/*    </li>*/}
                            {/*    <li><a href="/">3</a>*/}
                            {/*    </li>*/}
                            {/*    <li><a href="/">4</a>*/}
                            {/*    </li>*/}
                            {/*    <li><a href="/">5</a>*/}
                            {/*    </li>*/}
                            {/*    <li><a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> </a>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

