import React, { useState } from 'react';
import './VendaSeuAtivo.css';
import Layout from '../template/Layout';
import api from "../services/api";
import SubHeader from "../components/SubHeader";
import { Helmet } from 'react-helmet';

export default function VendaSeuAtivo(props) {
  const [resultMessage, setResultMessage] = useState(null);
const [ativo, setAtivo] = useState("0");
const [leiOrcamentaria, setLeiOrcamentaria] = useState("0");
const [cobrancaJudicializada, setCobrancaJudicializada] = useState("0");
const [tipoGarantia, setTipoGarantia] = useState("0");
const [avalPj, setAvalPj] = useState("0");
const [avalPf, setAvalPf] = useState("0");
const [stress, setStress] = useState("0");

    const handleSubmit = async (event) => {
        event.preventDefault();
        document.getElementById('form-venda-seu-ativo').disabled = true;
        const { elements } = event.target;
        const nome = elements.nome.value;
        const email = elements.email.value;
        const fone = elements.fone.value;
        const oportunidade = !elements.oportunidade ? '' : elements.oportunidade.value;
        const descricao = !elements.descricao ? '' : elements.descricao.value;
        const vendedor = !elements.vendedor ? '' : elements.vendedor.value;
        const valorFace = !elements['valor-face'] ? '' : elements['valor-face'].value;
        const radioNatureza = !elements['radio-natureza'] ? '' : elements['radio-natureza'].value;
        const radioDevedor = !elements['radio-devedor'] ? '' : elements['radio-devedor'].value;
        const radioLeiOrcamentaria = !elements['radio-lei-orcamentaria'] ? '' : elements['radio-lei-orcamentaria'].value;
        const simQual = !elements['sim-qual'] ? '' : elements['sim-qual'].value;
        const video = !elements.video ? '' : elements.video.value;
        const devedor = !elements.devedor ? '' : elements.devedor.value;
        const nProcesso = !elements['n-processo'] ? '' : elements['n-processo'].value;
        const radioCobrancaJudicializada = !elements['radio-cobranca-judicializada'] ? '' : elements['radio-cobranca-judicializada'].value;
        const judicializadaN = !elements['judicializada-n'] ? '' : elements['judicializada-n'].value;
        const radioGarantia = !elements['radio-garantia'] ? '' : elements['radio-garantia'].value;
        const garantiaImovelEnd = !elements['garantia-imovel-end'] ? '' : elements['garantia-imovel-end'].value;
        const garantiaImovelN = !elements['garantia-imovel-n'] ? '' : elements['garantia-imovel-n'].value;
        const garantiaImovelCidade = !elements['garantia-imovel-cidade'] ? '' : elements['garantia-imovel-cidade'].value;
        const garantiaEstado = !elements['garantia-estado'] ? '' : elements['garantia-estado'].value;
        const radioTipoImovel = !elements['radio-tipo-imovel'] ? '' : elements['radio-tipo-imovel'].value;
        const nPrecatorio = !elements['n-precatorio'] ? '' : elements['n-precatorio'].value;
        const radioAvalPj = !elements['radio-aval-pj'] ? '' : elements['radio-aval-pj'].value;
        const avalPjCnpj = !elements['aval-pj-cnpj[]'] ? '' : elements['aval-pj-cnpj[]'].value;
        const radioAvalPf = !elements['radio-aval-pf'] ? '' : elements['radio-aval-pf'].value;
        const avalPfCpf = !elements['aval-pf-cpf[]'] ? '' : elements['aval-pf-cpf[]'].value;
        const radioStatusDevedor = !elements['radio-status-devedor'] ? '' : elements['radio-status-devedor'].value;
        const areaConstruida = !elements['area-construida'] ? '' : elements['area-construida'].value;
        const areaTerreno = !elements['area-terreno'] ? '' : elements['area-terreno'].value;
        const radioFatorStress = !elements['radio-fator-estres'] ? '' : elements['radio-fator-estres'].value;

        let data = {
          "devedor": devedor,
          "cobrancaJudicializada": radioCobrancaJudicializada,
          "email": email,
          "nomeOportunidade": oportunidade,
          "statusDevedor": radioStatusDevedor,
          "devedorPrecatorio": nPrecatorio,
          "estado": garantiaEstado,
          "vendedorCedente": vendedor,
          "link": video,
          "cidade": garantiaImovelCidade,
          "areaConstruida": areaConstruida,
          "cpf": avalPfCpf,
          "natureza": radioNatureza,
          "avalPf": radioAvalPf,
          "descricao": descricao,
          "numero": garantiaImovelN,
          "leiOrcamentaria": radioLeiOrcamentaria,
          "avalPj": radioAvalPj,
          "lei": leiOrcamentaria,
          "numeroProcessoCobranca": nProcesso,
          "valorFace": valorFace,
          "tipoImovel": radioTipoImovel,
          "tipoAtivo": ativo,
          "telefone": fone,
          "numeroProcesso": nProcesso,
          "areaTerreno": areaTerreno,
          "subtipo": "",
          "endereco": garantiaImovelEnd,
          "nome": nome,
          "matricula": nProcesso,
          "numeroPrecatorio": nPrecatorio,
          "cnpj": avalPjCnpj
        }

        const result = await api.post('/sale-active', data);
        setResultMessage('Ativo enviado com sucesso!');
        setTimeout(() => {
          setResultMessage(null)
        }, 5000)
    }

  return (
    <Layout>
        <Helmet>
           <title>Venda seu ativo</title>
        </Helmet>

        <section className="body-content" id="page-venda-seu-ativo">
            <section className="page-title background-title dark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-uppercase">Venda seu ativo </h3>
                        </div>
                    </div>
                </div>
            </section>
            <SubHeader nameImage='frase_ativos.jpg' />
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>A Jive pode ser uma solução para você monetizar o seu crédito, ativo judicial ou imóvel em situação complexa. Preencha o formulário abaixo para que possamos avaliar o seu ativo e entrar em contato.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">

                            <form id='form-venda-seu-ativo' className="contact-comments m-top-0 js-Mailer" onSubmit={handleSubmit} method="post">
                                <div className="row row-form">
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="nome">NOME *</label>
                                            <input type="text" name="name" id="nome" className="form-control" required />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="email">EMAIL *</label>
                                            <input type="email" name="email" id="email" className="form-control" required  data-error="Digite um e-mail válido" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="fone">TELEFONE *</label>
                                            <input type="text" name="fone" id="fone" className="form-control" required />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="oportunidade">NOME DA OPORTUNIDADE *</label>
                                            <input type="text" name="oportunidade" id="oportunidade" className="form-control" required />
                                            <span className="help-block">
                                                <i className="fa fa-question-circle"></i> Como você gostaria que o seu projeto fosse chamado
                                            </span>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="descricao">DESCRIÇÃO *</label>
                                            <textarea name="descricao" id="descricao" className="form-control" required></textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="vendedor">VENDEDOR/CEDENTE *</label>
                                            <input type="text" name="vendedor" id="vendedor" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="valor-face">VALOR DE FACE *</label>
                                            <input type="number" name="valor-face" id="valor-face" className="form-control" required />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <h4 className="text-uppercase m-bot-5">Escolha seu ativo *</h4>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <select className="form-control" required data-error="You must enter name" onChange={(event) => setAtivo(event.target.value)} >
                                                <option value="0">Selecione</option>
                                                <option value="1">Precatório</option>
                                                <option value="2">Direito Creditório</option>
                                                <option value="3">Crédito</option>
                                                <option value="4">Imóvel em Distressed</option>
                                            </select>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    { ativo === "1" &&
                                    <>
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 control-label" for="radio-natureza">NATUREZA *</label>
                                        <div className="col-md-12">
                                            <label className="radio-inline" for="radio-natureza-0">
                                                <input type="radio" required name="radio-natureza" id="radio-natureza-0" value="1" />
                                                Alimentar
                                            </label>
                                            <label className="radio-inline" for="radio-natureza-1">
                                                <input type="radio" required name="radio-natureza" id="radio-natureza-1" value="2"/>
                                                Não alimentar
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 control-label" for="radio-devedor">DEVEDOR *</label>
                                        <div className="col-md-12">
                                            <label className="radio-inline" for="radio-devedor-0">
                                                <input type="radio" required name="radio-devedor" id="radio-devedor-0" value="1" />
                                                União
                                            </label>
                                            <label className="radio-inline" for="radio-devedor-1">
                                                <input type="radio" required name="radio-devedor" id="radio-devedor-1" value="2"/>
                                                Estado
                                            </label>
                                            <label className="radio-inline" for="radio-devedor-1">
                                                <input type="radio" required name="radio-devedor" id="radio-devedor-1" value="2"/>
                                                Município
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="n-processo">Nº DO PROCESSO *</label>
                                            <input type="number" name="n-processo" id="n-processo" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="n-precatorio">Nº DO PRECATÓRIO (Se houver) </label>
                                            <input type="number" name="n-precatorio" id="n-precatorio" className="form-control" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 col-sm-12 control-label" for="radio-lei-orcamentaria">INSCRITO EM LEI ORÇAMENTÁRIA *</label>
                                        <div className="col-md-12 col-sm-12">
                                            <label className="radio-inline" for="radio-lei-orcamentaria-0">
                                                <input type="radio" required name="radio-lei-orcamentaria" id="radio-lei-orcamentaria-0" value="1" onChange={(event) => setLeiOrcamentaria(event.target.value)}/>
                                                Sim
                                            </label>
                                            <label className="radio-inline" for="radio-lei-orcamentaria-1">
                                                <input type="radio" required name="radio-lei-orcamentaria" id="radio-lei-orcamentaria-1" value="2" onChange={(event) => setLeiOrcamentaria(event.target.value)}/>
                                                Não
                                            </label>
                                        </div>
                                    </div>
                                    { leiOrcamentaria === "1" &&
                                        <>
                                            <div className="form-group clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <label for="sim-qual">Se sim, qual? *</label>
                                                    <input type="text" name="sim-qual" id="sim-qual" className="form-control" required/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="video">Caso você queira incluir um vídeo informando mais detalhes sobre o seu ativo, cole o link do vídeo no campo abaixo </label>
                                            <input type="text" name="video" id="video" className="form-control" />
                                            <span className="help-block">
                                                <i className="fa fa-question-circle"></i> Sugerimos que você faça o upload do seu vídeo no YouTube e depois cole o link dele aqui. <a href="https://support.google.com/youtube/answer/57407?hl=pt-BR#" target="_blank" rel="noopener noreferrer">Saiba como enviar vídeos no YouTube</a>.
                                            </span>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    </>
                                }

                                { ativo === "2" &&
                                    <>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="devedor">DEVEDOR *</label>
                                            <input type="text" name="devedor" id="devedor" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="n-processo">Nº DO PROCESSO *</label>
                                            <input type="number" name="n-processo" id="n-processo" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="video">Caso você queira incluir um vídeo informando mais detalhes sobre o seu ativo, cole o link do vídeo no campo abaixo </label>
                                            <input type="text" name="video" id="video" className="form-control" />
                                            <span className="help-block">
                                                <i className="fa fa-question-circle"></i> Sugerimos que você faça o upload do seu vídeo no YouTube e depois cole o link dele aqui. <a href="https://support.google.com/youtube/answer/57407?hl=pt-BR#" target="_blank" rel="noopener noreferrer">Saiba como enviar vídeos no YouTube</a>.
                                            </span>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    </>
                                }

                                { ativo === "3" &&
                                    <>
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="devedor">DEVEDOR *</label>
                                            <input type="text" name="devedor" id="devedor" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 col-sm-12 control-label" for="radio-cobranca-judicializada">COBRANÇA ESTÁ JUDICIALIZADA? *</label>
                                        <div className="col-md-12 col-sm-12">
                                            <label className="radio-inline" for="radio-cobranca-judicializada-0">
                                                <input type="radio" required name="radio-cobranca-judicializada" id="radio-cobranca-judicializada-0" value="1" onChange={(event) => setCobrancaJudicializada(event.target.value)}/>
                                                Sim
                                            </label>
                                            <label className="radio-inline" for="radio-cobranca-judicializada-1">
                                                <input type="radio" required name="radio-cobranca-judicializada" id="radio-cobranca-judicializada-1" value="2" onChange={(event) => setCobrancaJudicializada(event.target.value)}/>
                                                Não
                                            </label>
                                        </div>
                                    </div>
                                    { cobrancaJudicializada === "1" &&
                                        <>
                                            <div className="form-group clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <label for="judicializada-n">Se sim, NÚMERO DO PROCESSO *</label>
                                                    <input type="number" name="judicializada-n" id="judicializada-n" className="form-control" required/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 control-label" for="radio-garantia">EXISTE ALGUMA GARANTIA? *</label>
                                        <div className="col-md-12">
                                            <label className="radio-inline" for="radio-garantia-0">
                                                <input type="radio" required name="radio-garantia" id="radio-garantia-0" value="1" onChange={(event) => setTipoGarantia(event.target.value)}/>
                                                Imóvel em Alienação Fiduciária (AF)
                                            </label>
                                            <label className="radio-inline" for="radio-garantia-1">
                                                <input type="radio" required name="radio-garantia" id="radio-garantia-1" value="2" onChange={(event) => setTipoGarantia(event.target.value)}/>
                                                Imóvel em Hipoteca
                                            </label>
                                            <label className="radio-inline" for="radio-garantia-2">
                                                <input type="radio" required name="radio-garantia" id="radio-garantia-2" value="3" onChange={(event) => setTipoGarantia(event.target.value)}/>
                                                Bens Móveis
                                            </label>
                                            <label className="radio-inline" for="radio-garantia-3">
                                                <input type="radio" required name="radio-garantia" id="radio-garantia-3" value="4" onChange={(event) => setTipoGarantia(event.target.value)}/>
                                                Cessão de Recebíveis
                                            </label>
                                            <label className="radio-inline" for="radio-garantia-4">
                                                <input type="radio" required name="radio-garantia" id="radio-garantia-4" value="5" onChange={(event) => setTipoGarantia(event.target.value)}/>
                                                Outros
                                            </label>
                                        </div>
                                    </div>

                                    { (tipoGarantia === "1" || tipoGarantia === "2") &&
                                        <>
                                            {/* um ou outro */}
                                            <div className="form-group clearfix">
                                                <div className="col-md-9 col-sm-3">
                                                    <label for="garantia-imovel-end">ENDEREÇO *</label>
                                                    <input type="text" name="garantia-imovel-end" id="garantia-imovel-end" className="form-control" required/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3">
                                                    <label for="garantia-imovel-n">NÚMERO *</label>
                                                    <input type="number" name="garantia-imovel-n" id="garantia-imovel-n" className="form-control" required/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="form-group clearfix">
                                                <div className="col-md-9 col-sm-3">
                                                    <label for="garantia-imovel-cidade">Cidade *</label>
                                                    <input type="text" name="garantia-imovel-cidade" id="garantia-imovel-cidade" className="form-control" required/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3">
                                                    <label for="garantia-estado">Estado *</label>
                                                    <select name="garantia-estado" className="form-control" required id="garantia-estado">
                                                        <option value="0">Selecione</option>
                                                        <option value="AC">Acre</option>
                                                        <option value="AL">Alagoas</option>
                                                        <option value="AP">Amapá</option>
                                                        <option value="AM">Amazonas</option>
                                                        <option value="BA">Bahia</option>
                                                        <option value="CE">Ceará</option>
                                                        <option value="DF">Distrito Federal</option>
                                                        <option value="ES">Espírito Santo</option>
                                                        <option value="GO">Goiás</option>
                                                        <option value="MA">Maranhão</option>
                                                        <option value="MT">Mato Grosso</option>
                                                        <option value="MS">Mato Grosso do Sul</option>
                                                        <option value="MG">Minas Gerais</option>
                                                        <option value="PA">Pará</option>
                                                        <option value="PB">Paraíba</option>
                                                        <option value="PR">Paraná</option>
                                                        <option value="PE">Pernambuco</option>
                                                        <option value="PI">Piauí</option>
                                                        <option value="RJ">Rio de Janeiro</option>
                                                        <option value="RN">Rio Grande do Norte</option>
                                                        <option value="RS">Rio Grande do Sul</option>
                                                        <option value="RO">Rondônia</option>
                                                        <option value="RR">Roraima</option>
                                                        <option value="SC">Santa Catarina</option>
                                                        <option value="SP">São Paulo</option>
                                                        <option value="SE">Sergipe</option>
                                                        <option value="TO">Tocantins</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 control-label" for="radio-tipo-imovel">Tipo de Imóvel *</label>
                                        <div className="col-md-12">
                                            <label className="radio-inline" for="radio-tipo-imovel-0">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-0" value="1" />
                                                Comercial
                                            </label>
                                            <label className="radio-inline" for="radio-tipo-imovel-1">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-1" value="2" />
                                                Residencial
                                            </label>
                                            <label className="radio-inline" for="radio-tipo-imovel-2">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-2" value="3" />
                                                Industrial
                                            </label>
                                            <label className="radio-inline" for="radio-tipo-imovel-3">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-3" value="4" />
                                                Rural
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="n-processo">Número da Matrícula e do CRI</label>
                                            <input type="number" name="n-processo" id="n-processo" className="form-control"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="n-precatorio">Avaliação do Imóvel</label>
                                            <input type="number" name="n-precatorio" id="n-precatorio" className="form-control"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 col-sm-12 control-label" for="radio-aval-pj">EXISTE AVAL DE PESSOA JURÍDICA? *</label>
                                        <div className="col-md-12 col-sm-12">
                                            <label className="radio-inline" for="radio-aval-pj-0">
                                                <input type="radio" required name="radio-aval-pj" id="radio-aval-pj-0" value="1" onChange={(event) => setAvalPj(event.target.value)}/>
                                                Sim
                                            </label>
                                            <label className="radio-inline" for="radio-aval-pj-1">
                                                <input type="radio" required name="radio-aval-pj" id="radio-aval-pj-1" value="2" onChange={(event) => setAvalPj(event.target.value)}/>
                                                Não
                                            </label>
                                        </div>
                                    </div>
                                    { avalPj === "1" &&
                                        <>
                                            <div className="form-group clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <label for="aval-pj-cnpj">SE SIM, INDICAR CNPJ *</label>
                                                    <div className="box-mais-menos">
                                                        <input type="number" name="aval-pj-cnpj[]" id="aval-pj-cnpj" className="form-control" required/>
                                                        <div className="help-block with-errors"></div>
                                                        <i className="fa fa-plus-circle"></i>
                                                        <i className="fa fa-minus-circle"></i>
                                                    </div>
                                                    <div className="box-mais-menos">
                                                        <input type="number" name="aval-pj-cnpj[]" id="aval-pj-cnpj" className="form-control" required/>
                                                        <div className="help-block with-errors"></div>
                                                        <i className="fa fa-plus-circle"></i>
                                                        <i className="fa fa-minus-circle"></i>
                                                    </div>
                                                    <div className="box-mais-menos">
                                                        <input type="number" name="aval-pj-cnpj[]" id="aval-pj-cnpj" className="form-control" required/>
                                                        <div className="help-block with-errors"></div>
                                                        <i className="fa fa-plus-circle"></i>
                                                        <i className="fa fa-minus-circle"></i>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 col-sm-12 control-label" for="radio-aval-pf">EXISTE AVAL DE PESSOA FÍSICA? *</label>
                                        <div className="col-md-12 col-sm-12">
                                            <label className="radio-inline" for="radio-aval-pf-0">
                                                <input type="radio" required name="radio-aval-pf" id="radio-aval-pf-0" value="1" onChange={(event) => setAvalPf(event.target.value)}/>
                                                Sim
                                            </label>
                                            <label className="radio-inline" for="radio-aval-pf-1">
                                                <input type="radio" required name="radio-aval-pf" id="radio-aval-pf-1" value="2" onChange={(event) => setAvalPf(event.target.value)}/>
                                                Não
                                            </label>
                                        </div>
                                    </div>
                                    { avalPf === "1" &&
                                        <>
                                            <div className="form-group clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <label for="aval-pf-cpf">SE SIM, INDICAR CPF *</label>
                                                    <div className="box-mais-menos">
                                                        <input type="number" name="aval-pf-cpf[]" id="aval-pf-cpf" className="form-control" required/>
                                                        <div className="help-block with-errors"></div>
                                                        <i className="fa fa-plus-circle"></i>
                                                        <i className="fa fa-minus-circle"></i>
                                                    </div>
                                                    <div className="box-mais-menos">
                                                        <input type="number" name="aval-pf-cpf[]" id="aval-pf-cpf" className="form-control" required/>
                                                        <div className="help-block with-errors"></div>
                                                        <i className="fa fa-plus-circle"></i>
                                                        <i className="fa fa-minus-circle"></i>
                                                    </div>
                                                    <div className="box-mais-menos">
                                                        <input type="number" name="aval-pf-cpf[]" id="aval-pf-cpf" className="form-control" required/>
                                                        <div className="help-block with-errors"></div>
                                                        <i className="fa fa-plus-circle"></i>
                                                        <i className="fa fa-minus-circle"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 col-sm-12 control-label" for="radio-status-devedor">STATUS DO DEVEDOR</label>
                                        <div className="col-md-12 col-sm-12">
                                            <label className="radio-inline" for="radio-status-devedor-0">
                                                <input type="radio" required name="radio-status-devedor" id="radio-status-devedor-0" value="1"/>
                                                Operando
                                            </label>
                                            <label className="radio-inline" for="radio-status-devedor-1">
                                                <input type="radio" required name="radio-status-devedor" id="radio-status-devedor-1" value="2"/>
                                                Em Recuperação Judicial
                                            </label>
                                            <label className="radio-inline" for="radio-status-devedor-2">
                                                <input type="radio" required name="radio-status-devedor" id="radio-status-devedor-2" value="3"/>
                                                Em Falência
                                            </label>
                                            <label className="radio-inline" for="radio-status-devedor-3">
                                                <input type="radio" required name="radio-status-devedor" id="radio-status-devedor-3" value="4"/>
                                                Sem Informações
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="video">Caso você queira incluir um vídeo informando mais detalhes sobre o seu ativo, cole o link do vídeo no campo abaixo </label>
                                            <input type="text" name="video" id="video" className="form-control" />
                                            <span className="help-block">
                                                <i className="fa fa-question-circle"></i> Sugerimos que você faça o upload do seu vídeo no YouTube e depois cole o link dele aqui. <a href="https://support.google.com/youtube/answer/57407?hl=pt-BR#" target="_blank" rel="noopener noreferrer">Saiba como enviar vídeos no YouTube</a>.
                                            </span>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    </>
                                }
                                { ativo === "4" &&
                                    <>
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 col-sm-12 control-label" for="radio-tipo-imovel">TIPO *</label>
                                        <div className="col-md-12 col-sm-12">
                                            <label className="radio-inline" for="radio-tipo-imovel-0">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-0" value="1"/>
                                                Apartamento
                                            </label>
                                            <label className="radio-inline" for="radio-tipo-imovel-1">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-1" value="2"/>
                                                Casa
                                            </label>
                                            <label className="radio-inline" for="radio-tipo-imovel-2">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-2" value="3"/>
                                                Terreno
                                            </label>
                                            <label className="radio-inline" for="radio-tipo-imovel-3">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-3" value="4"/>
                                                Galpão
                                            </label>
                                            <label className="radio-inline" for="radio-tipo-imovel-4">
                                                <input type="radio" required name="radio-tipo-imovel" id="radio-tipo-imovel-4" value="5"/>
                                                Fazenda
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-6 col-sm-6">
                                            <label for="area-construida">ÁREA CONSTRUÍDA (em m²) *</label>
                                            <input type="number" name="area-construida" id="area-construida" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label for="area-terreno">ÁREA DE TERRENO (em m²) *</label>
                                            <input type="number" name="area-terreno" id="area-terreno" className="form-control" required />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-9 col-sm-3">
                                            <label for="garantia-imovel-end">ENDEREÇO *</label>
                                            <input type="text" name="garantia-imovel-end" id="garantia-imovel-end" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-3 col-sm-3">
                                            <label for="garantia-imovel-n">NÚMERO *</label>
                                            <input type="number" name="garantia-imovel-n" id="garantia-imovel-n" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <div className="col-md-9 col-sm-3">
                                            <label for="garantia-imovel-cidade">Cidade *</label>
                                            <input type="text" name="garantia-imovel-cidade" id="garantia-imovel-cidade" className="form-control" required/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                        <div className="col-md-3 col-sm-3">
                                            <label for="garantia-estado">Estado *</label>
                                            <select name="garantia-estado" className="form-control" required id="garantia-estado">
                                                <option value="0">Selecione</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group clearfix">
                                        <label className="col-md-12 col-sm-12 control-label" for="radio-fator-estress">ALGUM FATOR DE "STRESS"? *</label>
                                        <div className="col-md-12 col-sm-12">
                                            <label className="radio-inline" for="radio-fator-estress-0">
                                                <input type="radio" required name="radio-fator-estress" id="radio-fator-estress-0" value="1" onChange={(event) => setStress(event.target.value)}/>
                                                Sim
                                            </label>
                                            <label className="radio-inline" for="radio-fator-estress-1">
                                                <input type="radio" required name="radio-fator-estress" id="radio-fator-estress-1" value="2" onChange={(event) => setStress(event.target.value)}/>
                                                Não
                                            </label>
                                        </div>
                                    </div>
                                    { stress === "1" &&
                                        <>
                                            <div className="form-group clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <label for="sim-qual-stress">Se sim, qual? *</label>
                                                    <input type="number" name="sim-qual-stress" id="sim-qual-stress" className="form-control" required/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group clearfix">
                                        <div className="col-md-12 col-sm-12">
                                            <label for="video">Caso você queira incluir um vídeo informando mais detalhes sobre o seu ativo, cole o link do vídeo no campo abaixo </label>
                                            <input type="text" name="video" id="video" className="form-control" />
                                            <span className="help-block">
                                                <i className="fa fa-question-circle"></i> Sugerimos que você faça o upload do seu vídeo no YouTube e depois cole o link dele aqui. <a href="https://support.google.com/youtube/answer/57407?hl=pt-BR#" target="_blank" rel="noopener noreferrer">Saiba como enviar vídeos no YouTube</a>.
                                            </span>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    </>
                                }

                                <input type="hidden" name="id" value="FORM_ALT" />
                                <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                    <p>* Campos obrigatórios </p>
                                </div>
                                { ativo !== "0" &&
                                    <>
                                        <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                            <button type="submit" className="btn btn-small btn-dark-solid ">ENVIAR</button>
                                        </div>
                                    </>
                                }

                                </div>

                              { resultMessage && (<p className='alert alert-success'>
                                {resultMessage}
                              </p>)
                              }

                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

