import React, {useEffect, useState} from 'react';
import './JiveDistressedFimIiiCp.css';
import Layout from '../template/Layout';
import api from "../services/api";
import { Link } from 'react-router-dom';
import {trackPromise} from "react-promise-tracker";
import SubHeader from "../components/SubHeader";
import {useTranslation} from "react-i18next";
import {Trans} from "react-i18next";
import {getLng} from "../helpers";
import { Helmet } from 'react-helmet';

export default function JiveDistressedFimIiiCp(props) {
    const [fund, setFund] = useState({});
    const {  i18n } = useTranslation();

    const activeLng = getLng(i18n)

    useEffect(() => {
        findFund();
    }, [])

    const findFund = () => {
        trackPromise(
          new Promise((resolve) => {
              api.get('/p/funds/fim-iii-cp')
                .then(({data}) => {
                    if (!data) {
                        resolve();
                        return;
                    }
                    let aux = {}
                    if (data.portuguese && data.portuguese.description) {
                        aux = {
                            id: data.id,
                            ...data.portuguese,
                        }
                    }
                    if (data.english && data.english.description) {
                        aux = {
                            id: data.id,
                            ...data.english,
                        }
                    }
                    setFund(aux);
                    window.scrollTo(0, 0)
                    resolve()
                }).catch(() => {
                  resolve()
              })
          })
        )
    }

  return (

    <Layout>
    <Helmet>
        <title>JIVE DISTRESSED FIM III CP</title>
    </Helmet>

    <section className="body-content" id="page-jive-distressed-fim-iii-cp">
        <section className="page-title background-title dark">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-uppercase">JIVE DISTRESSED FIM III CP </h3>
                    </div>
                </div>
            </div>
        </section>
        <SubHeader nameImage='frase_fundos.jpg' />
        <div className="page-content">

            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <section className="normal-tabs">
                            <ul className="nav nav-tabs">
                                <li className="active">
                                    <a data-toggle="tab" href="#tab-one"><Trans>OBJETIVO</Trans></a>
                                </li>
                                <li className="">
                                    <a data-toggle="tab" href="#tab-two"><Trans>PÚBLICO-ALVO</Trans></a>
                                </li>
                                <li className="">
                                    <a data-toggle="tab" href="#tab-tree"><Trans>POLÍTICA DE INVESTIMENTO</Trans></a>
                                </li>
                            </ul>
                            <div className="panel-body p-bot-0">
                                <div className="tab-content">
                                    <div style={{fontSize: 17}} id="tab-one" className="tab-pane active">
                                      <Trans>
                                          A política de investimento consiste em detectar as distorções de preços em diferentes Ativos nos vários mercados, bem como antecipar movimentos que se estejam formando, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.
                                      </Trans>
                                    </div>
                                    <div style={{fontSize: 17}} id="tab-two" className="tab-pane">
                                      <Trans>
                                          O fundo é destinado às aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos no regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.
                                      </Trans>
                                    </div>
                                  <div style={{fontSize: 17}} id="tab-tree" className="tab-pane">
                                    <Trans i18nKey={'jiveDistressedFimIIICpInvestmentPolicyIII'} />
                                    <ul>
                                      <li><Trans>(i) cotas de Fundos Alvo, que terão como ativos-alvo, direta ou indiretamente, Ativos Distressed, Ativos de Crédito, Ativos Oportunísticos e/ou Ativos Novas Teses, observada a regulamentação aplicável;</Trans></li>
                                      <li><Trans>(ii) cotas de Fundos Co-investimento;</Trans></li>
                                      <li><Trans>(iii) até 20% (vinte por cento) da totalidade do capital subscrito pelos Cotistas, para integralização de Cotas, em ativos negociados ou emitidos no exterior, observado, em qualquer caso e a qualquer tempo, que tal percentual não poderá exceder o limite de 40% (quarenta por cento) de seu patrimônio líquido, nos termos da regulamentação aplicável;</Trans></li>
                                        { activeLng !== 'en' && (<li><Trans>(iv) os recursos disponíveis no caixa do Fundo poderão ser aplicados, exclusivamente, em Outros Ativos.</Trans></li>)}
                                    </ul>
                                  </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>


        { activeLng !== 'en' && (<div className="page-content p-top-0">
            <div className="container">
                <h3 className="text-uppercase m-bot-0"><Trans>Informativo mensal</Trans></h3>
                <p><Trans>Faça o download do PDF do mês vigente</Trans></p>
                <div className="table-responsive ">
                    <table className="table-downloads">
                        <tr>
                            <td>
                                <a href={fund.attachment} target={'_blank'}>
                                    {fund.description}
                                    <i className="fa fa-download"></i>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        )}

    </section>
</Layout>
  )
}
