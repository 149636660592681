/* eslint-disable */
import React, {useEffect, useState} from 'react';
import './Oportunidades.css';
import Layout from '../template/Layout';
import api from "../services/api";
import {trackPromise} from "react-promise-tracker";
import {useTranslation} from "react-i18next";
import {getElementByLang, getLng} from "../helpers";
import {Trans} from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Oportunidades(props) {
    const [opportunities, setOpportunities] = useState([])
    const {  t, i18n } = useTranslation();
    const activeLng = getLng(i18n)

  const [customClass] = useState('custom-header');
  useEffect(() => {
    document.body.classList.add(customClass);
    return () => {
      document.body.classList.remove(customClass);
    }
  },[customClass]);

    useEffect(() => {
        listOpportunities();
    }, [])

    const listOpportunities = () => {
        trackPromise(
          new Promise((resolve) => {
              api.get('/p/opportunities')
                .then(({data}) => {
                    const aux = data.map(item => {
                        const element = getElementByLang(item, activeLng, 'title')
                        return {
                            id: item.id,
                            ...element,
                            scheduledAt: item.scheduledAt,
                        }
                    });
                    setOpportunities(aux);
                    window.scrollTo(0, 0)
                    resolve();
                }).catch(() => {
                  resolve()
              });
          })
        )
    }

    const renderOpportunities = () => {
        return opportunities.map(i => (
          <div key={i.id} className="col-md-6 col-sm-12 opportunity-item">
              <div className="border-box">
                  <div className="desc">
                    <b>{i.title}</b>
                    <br />
                    <br />
                    <span dangerouslySetInnerHTML={{__html: i.text}}></span>
                  </div>
              </div>
          </div>
        ))
    }

  return (
    <Layout>
      <Helmet>
        <title>{t('Oportunidades')}</title>
      </Helmet>

      <section className="page-title title-margin">
        <div className="container">
          <h4 className="text-uppercase"><Trans>Oportunidades</Trans></h4>
        </div>
      </section>
        <section className="body-content" id="page-oportunidades">



            <div className="page-content">
                <div className="feature-box-grid container clearfix">

                    <div className="text-center ">
                        <h3 className="text-uppercase m-bot-5">
                          <Trans>
                            Quer trabalhar conosco?
                          </Trans>
                        </h3>
                      <h3 className="text-uppercase m-bot-5">
                        <Trans>
                          Acompanhe nossas oportunidades no <a href="https://www.linkedin.com/company/jive-investments/jobs" rel="noopener noreferrer" target="_blank">LinkedIn</a>.
                        </Trans>
                      </h3>

                    </div>

                    {/* <div className="heading-title text-center inline-block m-bot-50">
                    <p>Quer trabalhar conosco? Acompanhe nossas oportunidades no <a href="https://www.linkedin.com/company/jive-investments/jobs" rel="noopener noreferrer" target="_blank">LinkedIn</a> </p>
                    <p>Confira também as oportunidades abaixo:</p>
                    </div> */}

                    { renderOpportunities() }
                </div>
            </div>
        </section>
    </Layout>
  )
}
