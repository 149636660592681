import React from 'react';
import './SubHeader.css';
import {useTranslation} from "react-i18next";
import {getLng} from "../helpers";

export default ({nameImage}) => {
    const { i18n } = useTranslation();

    const activeLng = getLng(i18n)

    const nameImageLng = activeLng === 'en' ? nameImage.replace('.jpg', '_ing.jpg') : nameImage;

    return (
        <div className='sub-header'>
            <div className='container'>
                <img src={`assets/img/parallax/${nameImageLng}`} alt=''/>
            </div>
        </div>
    )
}
