/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './App.css';
import Routes from './routes';
import { usePromiseTracker } from "react-promise-tracker";
import BounceLoader from "react-spinners/BounceLoader";

import CoockieModal from './components/CoockieModal';
import { ModalHome } from './components/ModalHome';

import modalContext from './context/modal-context';

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

    return (
      promiseInProgress && <div style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        zIndex: '1800',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <BounceLoader color={'#C4C4C4'} />
      </div>
     );
   }

function App() {
  const [showSettingsModal, setSettingsShowModal] = useState(false);

  return (
    <div className="App">
      <modalContext.Provider value={{ showSettingsModal, setSettingsShowModal }}>
        <LoadingIndicator/>
        <Routes />
        <CoockieModal />
        <ModalHome />
      </modalContext.Provider>
    </div>
  );
}

export default App;
