/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './Eventos.css';
import Layout from '../template/Layout';
import api from "../services/api";
import Pagination from "react-js-pagination";
import {trackPromise} from "react-promise-tracker";
import {formatDate, getElementByLang, getLng} from "../helpers";
import {useTranslation} from "react-i18next";
import {Trans} from "react-i18next";
import { Helmet } from 'react-helmet';

export default function Eventos(props) {
    const [events, setEvents] = useState([]);
    const [pagination, setPagination] = useState(null);
    const {  t, i18n } = useTranslation();
    const activeLng = getLng(i18n);

    const [customClass] = useState('custom-header');
    useEffect(() => {
        document.body.classList.add(customClass);
        return () => {
            document.body.classList.remove(customClass);
        }
    },[customClass]);

    const changePage = (page) => {
        trackPromise(
            new Promise((resolve) => {
                api.get('/p/events', {
                    params: {
                        page,
                    }
                })
                    .then(({data}) => {
                        const {docs, ...pageInfos} = data;
                        setPagination(pageInfos);
                        const aux = docs.map(item => {
                            const element = getElementByLang(item, activeLng, 'title')
                            return {
                                id: item.id,
                                ...element,
                                scheduledAt: item.scheduledAt,
                            }
                        });
                        setEvents(aux);
                        window.scrollTo(0, 0)
                        resolve();
                    })
                    .catch(() => { resolve() })
            })
        ).then()
    }

    useEffect(() => {
        changePage(1);
    }, [])



    const dateToDay = (isoDate) => {
        return formatDate(new Date(isoDate), 'dd')
    }

    const dateToMonth = (isoDate) => {
        return formatDate(new Date(isoDate), 'MMM')
    }

    const dateToYear = (isoDate) => {
        return formatDate(new Date(isoDate), 'yyyy')
    }

    const renderEvents = () => {
        return events.map(i => (
          <div className="col-md-12" key={i.id}>
              <div className="blog-classic">
                  <div className="date">
                      {dateToDay(i.scheduledAt)}
                      <span>{dateToMonth(i.scheduledAt)} {dateToYear(i.scheduledAt)}</span>
                  </div>
                  <div className="blog-post">
                      <h4 className="text-uppercase m-top-0"><a {...i.link? { href: i.link, target: i.isBlankTarget ? '_blank' : '' } : {}}>{i.title}</a></h4>
                      <div style={{fontSize: 17}} dangerouslySetInnerHTML={{__html: i.text}}></div>
                  </div>
              </div>
          </div>
        ))
    }

    const handlePageChange = (number) => {
        changePage(number);
    }

  return (
    <Layout>
        <Helmet>
            <title>{t('Eventos')}</title>
        </Helmet>

        <section className="page-title title-margin">
            <div className="container">
                <h4 className="text-uppercase"><Trans>Eventos</Trans></h4>
            </div>
        </section>
        <section className="body-content" id="page-eventos">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        </div>
                    </div>
                    <div className="row">
                        {
                            renderEvents()
                        }
                        <div id='events-pagination' className="text-center">
                            { pagination && (
                              <Pagination
                                activePage={pagination.page}
                                itemsCountPerPage={pagination.limit}
                                totalItemsCount={pagination.totalDocs}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                              />
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

