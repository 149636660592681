import React from 'react';
import './Empresa.css';
import Layout from '../template/Layout';
import SubHeader from '../components/SubHeader'
import {useTranslation, Trans} from "react-i18next";
import { Helmet } from 'react-helmet';

export default function Empresa(props) {
    const { t } = useTranslation();

  return (
    <Layout>
        <Helmet>
            <title>{t('A Empresa')}</title>
        </Helmet>

        <section className="body-content" id="page-empresa">
            <section className="page-title background-title dark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-uppercase"><Trans>A Empresa</Trans></h3>
                        </div>
                    </div>
                </div>
            </section>
            <SubHeader nameImage='frase_empresa.jpg' />
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img src="assets/img/foto_empresa.jpg" className="img-empresa img-responsive" alt="A empresa"/>
                        </div>
                        <div className="col-md-6" style={{fontSize: 17}}>
                            <p><Trans>A Jive Investments é uma plataforma integrada de gestão de ativos alternativos com foco na originação, aquisição e recuperação de créditos inadimplidos (“NPL”), imóveis em situações complexas, ativos judiciais e outros ativos <i>distressed</i>.  Pioneira no investimento em ativos <i>distressed</i> no Brasil e maior <i>player</i> independente do mercado, a Jive possui um processo de recuperação de crédito totalmente integrado, incluindo a gestão de processos judiciais e administrativos de cobrança e o gerenciamento de imóveis e outros ativos físicos recuperados, dentro de uma plataforma única e escalável com utilização intensiva de tecnologia para a localização, precificação e recuperação de ativos.</Trans></p>
                            <p className="m-bot-0"><Trans>A Jive investe continuamente em inovação e tecnologia de ponta para oferecer a melhor solução em recuperação de créditos, imóveis e outros ativos <i>distressed</i> para bancos, empresas e demais credores.</Trans></p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="page-content p-top-0 p-bot-0">
                <div className="container content">
                    <div className='content-bullet'>
                        <div className='bullet'>
                            <div>
                                <div>
                                    <h6><small><Trans>+de</Trans></small> 1490</h6>
                                    <p>
                                      <Trans>
                                        investidores
                                        no Brasil e
                                        no exterior
                                      </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet bullet-mobile' style={{top: 210}}>
                            <div>
                                <div>
                                    <h6><Trans>R$ 8.2 Bi</Trans></h6>
                                    <p>
                                      <Trans>
                                        em ativos
                                        sob gestão
                                        (AUM)
                                    </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet-fake'></div>
                        <div className='bullet'>
                            <div>
                                <div>
                                    <h6><Trans>R$ 26 Bi</Trans></h6>
                                    <p>
                                      <Trans>
                                        em valor
                                        de face
                                      </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet bullet-mobile' style={{top: 630}}>
                            <div>
                                <div>
                                    <h6><small><Trans>+de</Trans></small> 310</h6>
                                    <p>
                                      <Trans>
                                        colaboradores
                                      </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bullet-fake'></div>
                        <div className='content-bullet-float'>
                            <div className='bullet'>
                                <div>
                                    <div>
                                        <h6><Trans>R$ 8.2 Bi</Trans></h6>
                                        <p>
                                          <Trans>
                                            em ativos
                                            sob gestão
                                            (AUM)
                                          </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bullet'>
                                <div>
                                    <div>
                                        <h6><small><Trans>+de</Trans></small> 310</h6>
                                        <p>
                                          <Trans>
                                            colaboradores
                                          </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-uppercase m-bot-15">
                                <Trans>HISTÓRIA</Trans>
                            </h3>
                            <p className="m-bot-15" style={{fontSize: 17}}>
                                <Trans>Em 2010, a Jive Investments foi criada para participar do leilão dos ativos do Lehman Brothers no Brasil. Investidores tanto locais quanto internacionais fizeram propostas para o portfólio do Lehman Brothers, mas a Jive apresentou uma proposta mais simples e adquiriu o portfólio com a aprovação da Corte de Falências de Nova Iorque em dezembro de 2010.</Trans>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="page-content content-infos">
                <div className="container">
                    <div className="row row-infos">
                        <div className='left-background'></div>
                        <div className='right-background'></div>
                        <div className='row'>
                            <div className='col-sm-6 col-xs-6 col-md-6 left-item'>
                                <img src='assets/img/parallax/timeline_icone.png' alt=''/>
                                <b>2010</b>
                                <p>
                                  <Trans i18nKey='history2010'/>
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-offset-6 col-xs-offset-6 col-sm-offset-6 col-sm-6 col-xs-6 col-md-6 right-item'>
                                <img src='assets/img/parallax/timeline_icone.png' alt=''/>
                                <b>2011-2014</b>
                                <p>
                                  <Trans i18nKey='history2011_2014'/>
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 col-xs-6 col-md-6 left-item'>
                                <img src='assets/img/parallax/timeline_icone.png' alt=''/>
                                <b>2015</b>
                                <p>
                                  <Trans>
                                    Distribuição do Jive <i>Distressed</i> FIM CP IE
                                    (R$ 500 milhões)
                                  </Trans>
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-offset-6 col-xs-offset-6 col-sm-offset-6 col-md-6 col-sm-6 col-xs-6 right-item'>
                                <img src='assets/img/parallax/timeline_icone.png' alt=''/>
                                <b>2018</b>
                                <p>
                                    <Trans i18nKey='history2018'/>
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 col-xs-6 col-md-6 left-item'>
                                <img src='assets/img/parallax/timeline_icone.png' alt=''/>
                                <b>2020</b>
                                <p>
                                    <Trans i18nKey='history2020'/>
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-offset-6 col-xs-offset-6 col-sm-offset-6 col-md-6 col-sm-6 col-xs-6 right-item'>
                                <img src='assets/img/parallax/timeline_icone.png' alt=''/>
                                <b>2021</b>
                                <p>
                                    <Trans i18nKey='history2021'/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    </Layout>
  )
}


