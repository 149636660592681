import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import modalContext from '../context/modal-context';

function Rodape() {
    const { setSettingsShowModal } = useContext(modalContext);

    return (
        <footer id="footer" className="dark footer-2">
            <div className="primary-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <h5 className="text-uppercase m-bot-0"><Trans>ENDEREÇO</Trans></h5>
                                    <p>
                                        <Trans i18nKey= 'addressJive' />
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-12 p-lef-0 box-telefone-fot">
                                    <h5 className="text-uppercase m-bot-0"><Trans>TELEFONE</Trans></h5>
                                    <p className="fone"><span>+55 (11)</span> 3500-5055</p>
                                    <h5 className="text-uppercase m-bot-0 m-top-15"><Trans>CANAL DE DENÚNCIAS</Trans></h5>
                                    <p><a href="https://app.compliasset.com/jive" target='_blank' className="link-denuncia"><Trans>Envie a sua denúncia aqui</Trans></a></p>
                                    <p><Trans>Conheça nossa página de <a href="/compliance" className="link-denuncia"><i>compliance</i></a></Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="row">
                                <div className="col-md-4 col-sm-12 p-lef-0 box-social-fot">
                                    <h5 className="text-uppercase m-bot-5"><Trans>SIGA-NOS</Trans></h5>
                                    <a href="https://www.linkedin.com/company/jive-investments/ " target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                    <a href="https://www.instagram.com/jiveinvestments/ " target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCs6KLpOkg9afXN19ncb11Mw " target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-youtube"></i>
                                    </a>
                                </div>
                                <div className="col-md-4 col-sm-12 p-lef-0 logo-pri">
                                    <img src="assets/img/PRI_Logo_reduzido.png" alt="LOGO PRI" />
                                </div>
                                <div className="col-md-4 col-sm-12 m-bot-0 text-right">
                                    <img src="assets/img/Selo-Gestao-Recursos-Colorido-01.png" alt="Logo Jive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondary-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span><Trans>Fundos de investimento não contam com garantia do administrador do fundo, do gestor da carteira, de qualquer mecanismo de seguro, ou, ainda, do Fundo Garantidor de Crédito – FGC. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. Leia o prospecto e o regulamento antes de investir. © Jive Investments - Todos os direitos reservados - <a href="/disclaimer"><i>Disclaimer</i></a></Trans></span>
                        </div>

                        <div className="col-md-12 text-center">
                            <button
                                class="settings-cookie-modal"
                                onClick={() => setSettingsShowModal(true)}
                            >
                                <Trans>Configurações de cookies</Trans>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Rodape;
