import React, { useEffect, useState } from 'react';
import './JiveDistressedFimIiCpIe.css';
import Layout from '../template/Layout';
import api from "../services/api";
import { Link } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import SubHeader from "../components/SubHeader";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import {getLng} from "../helpers";
import { Helmet } from 'react-helmet';

export default function JiveDistressedFimIiCpIe(props) {
  const [fund, setFund] = useState({});
  const {  i18n } = useTranslation();

  const activeLng = getLng(i18n)

  const InvestmentPolicy = () => {
    if (activeLng === 'en') {
      return (
        <>
          <li>Quotas of the Fund Jive Consórcios I (FIDC-NP)</li>
          <li>Quotas from others (i) FIDCS NP, (ii) FIPs with properties as target assets (iii) FIIs (real estate funds)</li>
        </>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    findFund();
  }, [])

  const findFund = () => {
    trackPromise(
      new Promise((resolve) => {
        api.get('/p/funds/fim-ii-cp')
          .then(({ data }) => {
            if (!data) {
              resolve();
              return;
            }
            let aux = {}
            if (data.portuguese && data.portuguese.description) {
              aux = {
                id: data.id,
                ...data.portuguese,
              }
            }
            if (data.english && data.english.description) {
              aux = {
                id: data.id,
                ...data.english,
              }
            }
            setFund(aux);
            window.scrollTo(0, 0)
            resolve()
          }).catch(() => { resolve() });
      })
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>JIVE DISTRESSED FIM II CP</title>
      </Helmet>

      <section className="body-content" id="page-jive-distressed-fim-ii-cp-ie">
        <section className="page-title background-title dark">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-uppercase">Jive Distressed FIM II CP </h3>
              </div>
            </div>
          </div>
        </section>
        <SubHeader nameImage='frase_fundos.jpg' />
        <div className="page-content">

          <div className="container">
            <div className="row">
              <div className="col-md-12">

                <section className="normal-tabs">
                  <ul className="nav nav-tabs">
                    <li className="active">
                      <a data-toggle="tab" href="#tab-one"><Trans>OBJETIVO</Trans></a>
                    </li>
                    <li className="">
                      <a data-toggle="tab" href="#tab-two"><Trans>PÚBLICO-ALVO</Trans></a>
                    </li>
                    <li className="">
                      <a data-toggle="tab" href="#tab-tree"><Trans>POLÍTICA DE INVESTIMENTO</Trans></a>
                    </li>
                  </ul>
                  <div className="panel-body p-bot-0">
                    <div className="tab-content">
                      <div style={{ fontSize: 17 }} id="tab-one" className="tab-pane active">
                        <Trans>
                          A política de investimento consiste em detectar as distorções de preços em diferentes ativos nos vários mercados, bem como antecipar movimentos que estejam se formando, com o objetivo de superar constantemente a variação do CDI, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.
                        </Trans>
                      </div>
                      <div style={{ fontSize: 17 }} id="tab-two" className="tab-pane">
                        <Trans>
                          O fundo é destinado às  aplicações de investidores profissionais, que busquem a valorização de suas cotas e aceitem assumir os riscos descritos neste regulamento, aos quais os investimentos do fundo e, consequentemente, seus cotistas estão expostos, em razão da política de investimento do fundo e à forma de constituição de condomínio, dado que as cotas não admitem resgate.
                        </Trans>
                      </div>
                      <div style={{ fontSize: 17 }} id="tab-tree" className="tab-pane">
                        <Trans>
                          Os recursos serão alocados exclusivamente nos seguintes ativos de veículos
                        </Trans>
                        <ul>
                          <li><Trans>Cotas do fundo de liquidação financeira FIDC NP</Trans></li>
                          <li><Trans>Cotas do Jive Distressed Allocation II FIC FIM CP</Trans></li>
                          <li><Trans>Cotas do CSHG Jive D Allocation II FIC FIM CP IE</Trans></li>
                          <li><Trans>Cotas do Jive Distressed II FIM CP INVEST EX</Trans></li>
                          <InvestmentPolicy />
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        {activeLng !== 'en' && (<div className="page-content p-top-0">
          <div className="container">
            <h3 className="text-uppercase m-bot-0"><Trans>Informativo mensal</Trans></h3>
            <p><Trans>Faça o download do PDF do mês vigente</Trans></p>
            <div className="table-responsive ">
              <table className="table-downloads">
                <tr>
                  <td>
                    <a href={fund.attachment} target={'_blank'}>
                      {fund.description}
                      <i className="fa fa-download"></i>
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        )}

      </section>
    </Layout>
  )
}
