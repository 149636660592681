import {addMinutes, format} from "date-fns";
import fnsBr from "date-fns/locale/pt-BR";

export const formatDate = (date,
                           formatOutput) => format(
  addMinutes(date, date.getTimezoneOffset()),
  formatOutput,
   { locale: fnsBr }
);


export const getElementByLang = (element, lang, requiredFile) => {
    if (lang === 'en') {
        if (element.english[requiredFile]) {
            return element.english
        } else {
            return element.portuguese
        }
    } else {
        if (element.portuguese[requiredFile]) {
            return element.portuguese
        } else {
            return element.english
        }
    }
}

export const getLng = (i18n) => {
    if (typeof i18n.language === 'string') return i18n.language
    return i18n.language.lng
}