import React, { useState, useEffect } from 'react';
import './NoticiaInterna.css';
import Layout from '../template/Layout';

import { useHistory, Link } from 'react-router-dom';
import api from '../services/api'
import {trackPromise} from "react-promise-tracker";
import {formatDate} from "../helpers";
import {Trans} from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function NoticiaInterna(props) {
    const history = useHistory();
    const [customClass] = useState('custom-header');

    const [noticia, setNoticia] = useState({ portuguese: {}, hashtags: [] });
    const [hashtag, setHashtag] = useState('');
    const [previous, setNext] = useState(null);
    const [next, setPrevious] = useState(null);

    useEffect(() => {
        document.body.classList.add(customClass);
        return () => {
            document.body.classList.remove(customClass);
        }
    },[customClass]);

    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const q = params.get('q');
        const hashtagQ = params.get('hashtag');
        setHashtag(hashtagQ ? `&hashtag=${hashtagQ}` : '');
        if (!q) return history.push('/jive-na-midia')

        trackPromise(
          new Promise((resolve) => {
              api.get(`/p/reports/${q}`, {
                  params: hashtagQ && {
                      hashtag: hashtagQ
                  }
              })
                .then(({data}) => {
                    setNoticia(data.current);
                    setPrevious(data.previous);
                    setNext(data.next);
                    resolve()
                })
          })
        )
    }, [props.location.search, history])

    console.log(noticia.postedAt)

  return (
    <Layout>
        <Helmet>
          <title>Mídia</title>
        </Helmet>

        <section className="page-title title-margin">
            <div className="container">
                <h4 className="text-uppercase"><Trans>Jive na mídia</Trans></h4>
            </div>
        </section>
        <section className="body-content" id="page-noticia-interna">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blog-classic">
                                <div className="blog-post">
                                    <div className="full-width">
                                    <img src={noticia.attachmentLarge} alt=""/>
                                    </div>
                                    <h4 className="text-uppercase"><a href={`/noticia-interna?q=${noticia.id}`}>{noticia.portuguese.title}</a></h4>

                                    { noticia.postedAt && (<h6 style={{color: '#a7a7a7'}} className="text-uppercase">{formatDate(new Date(noticia.postedAt), 'dd/MM/yyyy')}- <Trans>FONTE:</Trans> {noticia.portuguese.source}</h6>) }

                                    <div  style={{fontSize: 17}} id='content-report' dangerouslySetInnerHTML={{ __html: noticia.portuguese.text }} />


                                    <div className="inline-block">

                                        <div className="widget-tags">
                                            <h6 className="text-uppercase">Tags </h6>
                                            {
                                                noticia.hashtags.map(i => (
                                                  <a href={`/jive-na-midia?hashtag=${i.id}`} key={i.id}>{i.portuguese.description}</a>
                                                ))
                                            }
                                        </div>
                                    </div>


                                    {/*<div className="clearfix inline-block m-top-50 m-bot-50">*/}
                                    {/*    <h6 className="text-uppercase">Compartilhe essa notícia </h6>*/}
                                    {/*    <div className="widget-social-link circle">*/}
                                    {/*        <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target='_blank'><i className="fa fa-facebook"></i> </a>*/}
                                    {/*        <a href="/"><i className="fa fa-twitter"></i> </a>*/}
                                    {/*        <a href="/"><i className="fa fa-copy"></i> </a>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}


                                    <div className="pagination-row" style={{marginTop: 32}}>

                                        <div className="pagination-post">
                                            <div className={previous ? "prev-post" : "prev-post disabled-nav-post"}>
                                                <a {...previous ? { href: `/noticia-interna?q=${previous}${hashtag}`} : {}}>
                                                    <div className="arrow">
                                                        <i className="fa fa-angle-double-left"></i>
                                                    </div>
                                                    <div className="pagination-txt">
                                                        <span><Trans>Notícia anterior</Trans></span>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="post-list-link">
                                                <a href="/jive-na-midia">
                                                    <i className="fa fa-home"></i>
                                                </a>
                                            </div>

                                            <div className={next ? "next-post" : "next-post disabled-nav-post"}>
                                                <a {...next ? { href: `/noticia-interna?q=${next}${hashtag}` } : {}}>
                                                    <div className="arrow">
                                                        <i className="fa fa-angle-double-right"></i>
                                                    </div>
                                                    <div className="pagination-txt">
                                                        <span><Trans>Próxima notícia</Trans></span>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

