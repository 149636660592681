/* eslint-disable */
import React, {useEffect, useState} from 'react';
import './Compliance.css';
import Layout from '../template/Layout';
import api from "../services/api";
import {trackPromise} from "react-promise-tracker";
import {getElementByLang, getLng} from "../helpers";
import {useTranslation, Trans} from "react-i18next";
import { Helmet } from 'react-helmet';

export default function Compliance(props) {
    const [compliances, setCompliances] = useState([]);
    const {  t, i18n } = useTranslation();
    const activeLng = getLng(i18n)

    const [customClass] = useState('custom-header');
    useEffect(() => {
        document.body.classList.add(customClass);
        return () => {
            document.body.classList.remove(customClass);
        }
    },[customClass]);

    useEffect(() => {
        changePage(1);
    }, [])

    const changePage = (page) => {
        trackPromise(
          new Promise((resolve) => {
              api.get('/p/compliances', {
                  params: {
                      page,
                  }
              })
                .then(({ data }) => {
                    const aux = data.map(item => {
                        const element = getElementByLang(item, activeLng, 'description')
                        return {
                            id: item.id,
                            ...element,
                        }
                    });
                    setCompliances(aux);
                    window.scrollTo(0, 0)
                    resolve()
                })
                .catch(err => resolve())
          }
          )
        )
    }


  return (
    <Layout>
    <Helmet>
        <title>{t('Compliance')}</title>
    </Helmet>

      <section className="page-title title-margin">
        <div className="container">
          <h4 className="text-uppercase"><Trans>Compliance</Trans></h4>
        </div>
      </section>
        <section className="body-content" id="page-compliance">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <h3 className="text-uppercase m-bot-0 text-center">
                              <Trans>
                              EM ATENDIMENTO ÀS EXIGÊNCIAS REGULATÓRIAS, E COM O OBJETIVO DE MANTER TRANSPARÊNCIA EM NOSSAS AÇÕES, DISPONIBILIZAMOS ABAIXO OS SEGUINTES MANUAIS, CÓDIGOS E POLÍTICAS
                              </Trans>
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
            <div className="page-content p-top-0">
                <div className="container">

                    {/* <p>Faça o download do documento que desejar:</p> */}
                    <div className="table-responsive ">
                        <table className="table-downloads">
                            {
                                compliances.map(i => (
                                  <tr>
                                      <td>
                                          <a
                                            target={'_blank'}
                                            href={i.attachment}>
                                              {i.description}
                                              <i className="fa fa-download"></i>
                                          </a>
                                      </td>
                                  </tr>
                                ))
                            }
                        </table>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 compliance-text">
                            <span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
  )
}

