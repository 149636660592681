import React, { useEffect, useState } from 'react';
import './JiveDistressedFimCpIe.css';
import Layout from '../template/Layout';
import api from "../services/api";
import { Link } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import SubHeader from "../components/SubHeader";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import {getLng} from "../helpers";
import { Helmet } from 'react-helmet';

export default function JiveDistressedFimCpIe(props) {
  const [fund, setFund] = useState({});
  const {  i18n } = useTranslation();

  const activeLng = getLng(i18n)

  const InvestmentPolicyBr = () => {
    if (activeLng !== 'en') {
      return (
        <>
          <li>Parte do patrimônio líquido, determinada em regulamento, pode ser investida em FIDCS com o objetivo de adquirir direitos de crédito pulverizados.</li>
          <li>Parte do patrimônio líquido pode ser investida em ativos no exterior, conforme determinado pelo estatuto social.</li>
          <li>Parte do patrimônio líquido pode ser investida em FIIS com o objetivo de adquirir ativos imobiliários com características específicas, conforme determinado pelo estatuto social.</li>
        </>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    findFund();
  }, [])

  const findFund = () => {
    trackPromise(
      new Promise((resolve) => {
        api.get('/p/funds/fim-cp-ie')
          .then(({ data }) => {
            if (!data) {
              resolve();
              return;
            }
            let aux = {}
            if (data.portuguese && data.portuguese.description) {
              aux = {
                id: data.id,
                ...data.portuguese,
              }
            }
            if (data.english && data.english.description) {
              aux = {
                id: data.id,
                ...data.english,
              }
            }
            setFund(aux);
            window.scrollTo(0, 0)
            resolve()
          }).catch(() => { resolve() })
      })
    )
  }

  return (
    <Layout>
      <Helmet>
        <title>JIVE DISTRESSED FIM CP</title>
      </Helmet>

      <section className="body-content" id="page-jive-distressed-fim-cp-ie">
        <section className="page-title background-title dark">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-uppercase">JIVE DISTRESSED FIM CP </h3>
              </div>
            </div>
          </div>
        </section>
        <SubHeader nameImage='frase_fundos.jpg' />
        <div className="page-content">

          <div className="container">
            <div className="row">
              <div className="col-md-12">

                <section className="normal-tabs">
                  <ul className="nav nav-tabs">
                    <li className="active">
                      <a data-toggle="tab" href="#tab-one"><Trans>OBJETIVO</Trans></a>
                    </li>
                    <li className="">
                      <a data-toggle="tab" href="#tab-two"><Trans>PÚBLICO-ALVO</Trans></a>
                    </li>
                    <li className="">
                      <a data-toggle="tab" href="#tab-tree"><Trans>POLÍTICA DE INVESTIMENTO</Trans></a>
                    </li>
                  </ul>
                  <div className="panel-body p-bot-0">
                    <div className="tab-content">
                      <div style={{ fontSize: 17 }} id="tab-one" className="tab-pane active">
                        <Trans>
                          A política de investimento consiste em detectar as distorções de preços em diferentes ativos nos vários mercados, bem como antecipar movimentos que estejam se formando, com o objetivo de superar constantemente a variação do CDI, sempre ajustados aos riscos inerentes às operações que realiza, alocando seus recursos de acordo com sua política de investimentos, nos termos do Artigo 5.2 do Regulamento e na regulamentação em vigor.
                                            </Trans>
                      </div>
                      <div style={{ fontSize: 17 }} id="tab-two" className="tab-pane">
                        <Trans>
                          O Fundo destina-se apenas a investidores qualificados e está atualmente fechado.
                                          </Trans>
                      </div>
                      <div style={{ fontSize: 17 }} id='tab-tree' className='tab-pane'>
                        <Trans>
                          Os recursos serão alocados exclusivamente nos seguintes ativos de veículos
                                          </Trans>
                        <ul>
                          <li><Trans>Cotas do <i>corporate</i> NPL - fundo de investimento em direitos creditórios não padronizados.</Trans></li>
                          <li><Trans>Cotas do Creditmix - fundo de investimento em direitos creditórios não padronizados.</Trans></li>
                          <li><Trans>Cotas do fundo de renegociação de débitos - fundo de investimento em direitos creditórios não padronizados.</Trans></li>
                          <li><Trans>Cotas do fundo de recuperação de ativos – fundo de investimento em direitos creditórios não padronizados.</Trans></li>
                          <li><Trans>Cotas de outros (i) FIDCS NP, (ii) FIPS com propriedades como ativos alvo, (iii) FIIS (fundos imobiliários).</Trans></li>
                          <InvestmentPolicyBr />
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        {activeLng !== 'en' && (
          <div className="page-content p-top-0">
            <div className="container">
              <h3 className="text-uppercase m-bot-0"><Trans>Informativo mensal</Trans></h3>
              <p><Trans>Faça o download do PDF do mês vigente</Trans></p>
              <div className="table-responsive ">
                <table className="table-downloads">
                  <tr>
                    <td>
                      <a href={fund.attachment} target={'_blank'}>
                        {fund.description}
                        <i className="fa fa-download"></i>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        )}

      </section>
    </Layout>
  )
}
