import React, {useState} from 'react';
import './Contato.css';
import Layout from '../template/Layout';
import api from "../services/api";
import {useTranslation, Trans} from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Contato(props) {
    const [resultMessage, setResultMessage] = useState(null);
    const {  t } = useTranslation();

    const handleSubmit = async (event) => {
        event.preventDefault();
        document.getElementById('submit-contato').disabled = true;
        const { elements } = event.target;
        const name = elements.name.value;
        const email = elements.email.value;
        const phone = elements.phone.value;
        const company = elements.company.value;
        const subject = elements.subject.value;
        const comments = elements.comments.value;
        const result = await api.post('/send-emails/contact', {
            name,
            email,
            phone,
            company,
            subject,
            comments
        });
        setResultMessage('Email enviado com sucesso!');
        document.getElementById("form-contato").reset();
        document.getElementById('submit-contato').disabled = false;
        setTimeout(() => {
            setResultMessage(null)
        }, 5000)
    }

  return (
    <Layout>
        <Helmet>
            <title>{t('Contato')}</title>
        </Helmet>

        <section className="page-title title-margin" id="page-contato">
            <div className="container">
                <h4 className="text-uppercase"><Trans>Contato</Trans></h4>
            </div>
        </section>
        <section className="body-content">
            <div className="page-content" id="contato">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-5">
                            <h4 className="text-uppercase m-bot-15"><Trans>ENDEREÇO</Trans></h4>
                            <p>
                              <Trans i18nKey='addressJive'/>
                            </p>
                            <address>
                                <p className='fone'><span>+55 (11) 3500-5055</span></p>
                                <p><i className="fa fa-envelope"></i> Propostas de negócio <a href="mailto:originacao@jiveinvestments.com">originacao@jiveinvestments.com</a></p>
                                <p><i className="fa fa-envelope"></i> Precatórios <a href="mailto:precatoriosgestao@jiveinvestments.com">precatoriosgestao@jiveinvestments.com</a></p>
                                <p><i className="fa fa-envelope"></i> Relação com investidores <a href="mailto:ir@jiveinvestments.com"> ir@jiveinvestments.com</a></p>
                                <p><i className="fa fa-envelope"></i> Carreira  <a href="mailto:dp@jiveinvestments.com"> dp@jiveinvestments.com</a></p>
                                <p><i className="fa fa-envelope"></i> Marketing & Comunicação<a href="mailto:comunicacao@jiveinvestments.com"> comunicacao@jiveinvestments.com</a></p>
                            </address>

                            <Trans>
                                Relações com a Mídia – TM Comunicações
                            </Trans>
                            <ul className="post-meta">
                                <li><i className="fa fa-user"></i>Luciana Alves
                                </li>
                                <li><i className="fa fa-envelope"></i><a href="mailto:luciana@tmcomunicacoes.com.br">luciana@tmcomunicacoes.com.br</a>
                                </li>
                                <li><i className="fa fa-phone"></i><a href="tel:55-21-981313106">+55 (21) 98131-3106</a>
                                </li>
                            </ul>
                            <ul className="post-meta">
                                <li><i className="fa fa-user"></i>Fernanda Malcher
                                </li>
                                <li><i className="fa fa-envelope"></i><a href="mailto:fernanda@tmcomunicacoes.com.br">fernanda@tmcomunicacoes.com.br</a>
                                </li>
                                <li><i className="fa fa-phone"></i><a href="tel:55-21-991189393">+55 (21) 99118-9393</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-8 col-sm-7">
                            <h4 className="text-uppercase m-bot-15"><Trans>Entre em contato</Trans></h4>

                            <form id='form-contato' onSubmit={handleSubmit} className="contact-comments m-top-0 js-Mailer" method="post">

                                { resultMessage && (<p className='alert alert-success'>
                                    {resultMessage}
                                </p>)
                                }

                                <div className="row row-form">

                                    <div className="col-md-6 col-sm-6 form-group">
                                        <label for="form-field-name"><Trans>Nome *</Trans></label>
                                        <input type="text" name="name" id="form-field-name" required className="form-control" maxLength="100" />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 form-group">
                                        <label for="form-field-email"><Trans>E-mail *</Trans></label>
                                        <input type="email" name="email" id="form-field-email" className="form-control" maxLength="100" required />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group col-md-6 col-sm-6">
                                        <label for="form-field-phone"><Trans>Telefone *</Trans></label>
                                        <input type="text" name="phone" id="form-field-phone" required className="form-control" maxLength="100" />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group col-md-6 col-sm-6">
                                        <label for="form-field-company"><Trans>Empresa *</Trans></label>
                                        <input type="text" name="company" id="form-field-company" required className="form-control" maxLength="100" />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                    <div className="form-group col-md-12 col-sm-12">
                                        <label for="form-field-subject"><Trans>Assunto *</Trans></label>
                                        <input type="text" name="subject" id="form-field-subject" required className="form-control" maxLength="100" />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group col-md-12 col-sm-12">
                                        <label for="form-field-comments"><Trans>Mensagem *</Trans></label>
                                        <textarea name="comments" id="form-field-comments" required className="cmnt-text form-control" rows="6" maxLength="400"></textarea>
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                        <p>
                                          <Trans>
                                            * Campos obrigatórios
                                          </Trans>
                                        </p>
                                    </div>

                                    <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                        <button id='submit-contato' type="submit" className="btn btn-small btn-dark-solid "><Trans>ENVIAR</Trans></button>
                                    </div>

                                </div>
                                <input type="hidden" name="id" value="FORM_ALT" />

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </Layout>
  )
}
