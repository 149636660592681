import React from 'react';

import './Home.css';

import Layout from '../template/Layout';
import Conteudo from '../template/Conteudo';

export default function Home(props) {


  return (
    <Layout>
    <Conteudo />
    </Layout>
  )
}