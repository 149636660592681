import React from 'react';

// export default function Loader() {

//   return (
//     <div id="tb-preloader">
//       <div className="tb-preloader-wave"></div>
//     </div>
//   );
// }

class Loader extends React.Component {
  componentDidMount() {
    //this.$el = $(this.el);
    //this.$el.Loader();
  }

  componentWillUnmount() {
    //this.$el.Loader('destroy');
  }

  render() {
    return (
      <div id="tb-preloader">
        <div className="tb-preloader-wave"></div>
      </div>
    );
  }

}
export default Loader;
