import React, { useEffect, useState } from 'react';
import './style.css';

export function ModalHome() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const lastShown = localStorage.getItem('modalLastShown');
        const now = new Date().getTime();

        if (!lastShown || now - lastShown > 24 * 60 * 60 * 1000) { // 24 horas em milissegundos
            setShowModal(true);
            localStorage.setItem('modalLastShown', now);
        }
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };

    if (!showModal) {
        return null;
    }

    return (
        <div className="modalhome" onClick={closeModal}>
            <div className="modalhome__body">
                <img src="/assets/img/pop-up-desk.png" alt="imagem" className="desktop" />
                <img src="/assets/img/pop-up-mobile.png" alt="imagem" className="mobile" />
            </div>

            <div className="modalhome__backdrop"></div>
        </div>
    );
}
